import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@kamino/services";
import * as DTO from "../interfaces";

const CATEGORIES_SERVICE = `${Constants.API_URL}/categories`;

export class CategoriesServices {
  @catchServiceException()
  static async get(coachId: string, type: string = "LIBRARY"): Promise<DTO.Category[]> {
    const { data } = await axios.get<DTO.Category[]>(`${CATEGORIES_SERVICE}/coaches/${coachId}`, {
      params: {
        type,
      },
      headers: {
        withCredentials: true,
      },
    });
    return data;
  }

  @catchServiceException()
  static async getAll(coachId: string, type: string = "LIBRARY"): Promise<DTO.Category[]> {
    const { data } = await axios.get<DTO.Category[]>(`${CATEGORIES_SERVICE}/coaches/${coachId}`, {
      params: {
        type,
        active: false,
      },
      headers: {
        withCredentials: true,
      },
    });
    return data;
  }

  @catchServiceException()
  static async add(
    coachId: string | number,
    category: DTO.Category,
    type: "LIBRARY" | "CAMPAIGN" | "VIDEO_SCRIPT" = "LIBRARY"
  ): Promise<DTO.Category> {
    const { data } = await axios.post<DTO.Category>(
      `${CATEGORIES_SERVICE}/`,
      {
        ...category,
        coachId,
        type,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async update(coachId: string, category: DTO.Category): Promise<DTO.Category> {
    const { data } = await axios.put<DTO.Category>(
      `${CATEGORIES_SERVICE}/${category.categoryId}`,
      {
        name: category.name.trim(),
        coachId,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
