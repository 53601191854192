import axios from "axios";
import { Constants, catchServiceException } from "@kamino/services";
import * as DTO from "../interfaces/dtos";

const SUBCOACHES_SERVICE = `${Constants.API_URL}/accounts`;
const COACHING_SUBCOACHES_SERVICE = `${Constants.API_URL}/coaches`;
export class SubCoachesServices {
 @catchServiceException()
  static async get(coachId: string): Promise<DTO.Page<DTO.SubCoach>> {
    const response = await axios.get<DTO.SubCoach[]>(`${SUBCOACHES_SERVICE}/${coachId}/subcoaches`, {
      withCredentials: true,
    });

    const searchResponse: DTO.Page<DTO.SubCoach> = {
      content: response.data,
    };
    return searchResponse;
  }

 @catchServiceException()
  static async updateSubCoachStatus(userId: number, status: DTO.UserStatuses): Promise<void> {
    const { data } = await axios.put<void>(
      `${SUBCOACHES_SERVICE}/subcoaches/${userId}/status/${status}`,
      {},
      {
        withCredentials: true,
      }
    );
    return data;
  }
  static async getInactive(coachId: string): Promise<DTO.Page<DTO.SubCoach>> {
    const response = await axios.get<DTO.SubCoach[]>(`${SUBCOACHES_SERVICE}/${coachId}/subcoaches?status=SUSPENDED`, {
      withCredentials: true,
    });
    const searchResponse: DTO.Page<DTO.SubCoach> = {
      content: response.data,
    };
    return searchResponse;
  }

 @catchServiceException()
  static async getPending(coachId: string): Promise<DTO.Page<DTO.SubCoach>> {
    const response = await axios.get<DTO.SubCoach[]>(`${SUBCOACHES_SERVICE}/${coachId}/subcoaches?status=PENDING`, {
      withCredentials: true,
    });
    const searchResponse: DTO.Page<DTO.SubCoach> = {
      content: response.data,
    };
    return searchResponse;
  }

 @catchServiceException()
  static async setSegmentation(
    coachId: string,
    segmentationRequest: DTO.SubCoachSegmentationRequest
  ): Promise<DTO.Page<DTO.SubCoach>> {
    const response = await axios.post<DTO.SubCoach[]>(
      `${COACHING_SUBCOACHES_SERVICE}/subcoaches/${coachId}/segmentation-details`,
      {
        ...segmentationRequest,
      },
      {
        withCredentials: true,
      }
    );

    const searchResponse: DTO.Page<DTO.SubCoach> = {
      content: response.data,
    };
    return searchResponse;
  }

 @catchServiceException()
  static async getSegmentation(coachId: string | number, userId: number): Promise<DTO.SegmentationDetail[]> {
    const response = await axios.get<DTO.SegmentationDetail[]>(
      `${COACHING_SUBCOACHES_SERVICE}/subcoaches/${coachId}/segmentation-details/${userId}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }
}
