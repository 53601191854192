import * as React from "react";
import { Modal, Header } from "semantic-ui-react";

import { PostPlayerRequest, Prompt, SendModeType } from "../../../../../../../interfaces";
import { AppContext } from "../../../../../../../providers";
import { PostModalForm, PlayerPostSuccess } from "../../../../../../../components";

import { modalReducer } from "./modalReducer";
import { useMessageToRepost } from "./hooks";
import { usePlayerSocialNetworks } from "../../../../../../../reducers";
import { getSocialNetworkMessages } from "../../../../../../../utils";

interface OwnProps {
  opened: boolean;
  prompt: Prompt;
  thumbnail: string;

  closeHandler: () => void;
}

type Props = OwnProps;

/**
 * Repost Flow Modal Components
 */
const RepostModalFC: React.FC<Props> = ({ opened, prompt, thumbnail, closeHandler }) => {
  const { userContext } = React.useContext(AppContext);
  const { playerSn } = usePlayerSocialNetworks();

  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false, success: false });
  const [postPlayerRequest, setPostPlayerRequest] = React.useState<PostPlayerRequest | undefined>(undefined);

  const { messageToPost } = useMessageToRepost(userContext!!, prompt);

  React.useEffect(() => {
    if (messageToPost !== undefined) {
      const request = {
        promptId: prompt.promptId,
        messageToPost,
        playerId: userContext?.player!!.id,
        mediaGroup: prompt.post,
        socialNetworkMessages: getSocialNetworkMessages(playerSn),
      } as PostPlayerRequest;

      setPostPlayerRequest(request);
    }
  }, [messageToPost, playerSn]);

  const close = () => {
    dispatch({ type: "close" });
    closeHandler();
  };

  const onSuccess = (isAutoPost: boolean, sendMode: SendModeType): void => {
    dispatch({ type: "success" });
    setPostPlayerRequest({ ...postPlayerRequest!!, sendMode, isAutoPost });
  };

  return (
    <Modal closeIcon size={"small"} open={state.open} onClose={close}>
      <Modal.Header>
        <Header.Content>
          <label className="postFailedTitle">New Post</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content className="repostFormModalContent">
        {!state.success && !state.loading && messageToPost && postPlayerRequest && (
          <PostModalForm
            playerId={userContext!!.player!.id}
            thumbnail={thumbnail}
            postPlayerRequest={postPlayerRequest}
            onSuccess={onSuccess}
          />
        )}

        {state.success && (
          <PlayerPostSuccess sendMode={postPlayerRequest?.sendMode!!} isAutoPost={postPlayerRequest?.isAutoPost} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export const RepostModal = RepostModalFC;
