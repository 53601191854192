import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Header, Grid, Form, Message, Button, Loader, Transition } from "semantic-ui-react";

import { get } from "lodash";
import { Formik } from "formik";
import { UserContext } from "@kamino/services";

import { SideBar, ProfileInformation, CoachContactInformation } from "../../../components";
import * as IT from "../../../interfaces";

import { AppContext } from "../../../providers";
import { ConfigContext } from "../../../contexts/appContexts";

import { SocialCoachSessionService, LoginStepServices, CoachSignUpStep } from "../../../services";
import { URLS, finishStep, saveImageToFirebase } from "../../../utils";

import { validationProfileSchema } from "./validations";
import { WhiteContainer, ContentGrid, InternalGrid, SCSubtitle, ColumnBasicPadding } from "../../../styling/baseStyle";
import "./styles.scss";

interface OwnProps {
  firstName?: string;
  lastName?: string;
  title?: string;
  company?: string;
  birthday?: string;
  phoneNumber?: string;
  successHandler: (user: UserContext) => void;
}

type Props = OwnProps & RouteComponentProps;

const CoachProfileFC: React.FC<Props> = ({ history }) => {
  const { setUserContext, userContext } = React.useContext(AppContext);
  const config: IT.ConfigProperties = React.useContext(ConfigContext);

  const [img, setImg] = React.useState<File | undefined>();
  const [saved, setSaved] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSuccess = () => {
    const basicCoach = userContext?.coach?.type && userContext?.coach?.type === "BASIC";

    history.push(basicCoach ? URLS.coach.pricePlan : URLS.coach.library);
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid>
        <Formik
          validateOnBlur={false}
          validateOnChange={true}
          initialValues={{
            firstName: get(userContext, "profile.firstName", ""),
            lastName: get(userContext, "profile.lastName", ""),
            title: get(userContext, "coach.title", ""),
            company: get(userContext, "coach.companyName", ""),
            birthday: get(userContext, "profile.birthday", ""),
            phoneNumber: get(userContext, "profile.phone", ""),
            sn: get(userContext, "profile.socialNetworks", []),
            contactPhone: get(userContext, "coach.contactPhone", get(userContext, "profile.phone", "")),
            contactEmail: get(userContext, "coach.contactEmail", get(userContext, "account.email", "")),
          }}
          validationSchema={validationProfileSchema}
          onSubmit={async (
            { firstName, lastName, title, company, birthday, phoneNumber, contactPhone, contactEmail, sn },
            { setSubmitting, setErrors, validateForm }
          ) => {
            try {
              const errors = await validateForm({
                firstName,
                lastName,
                title,
                company,
                birthday,
                phoneNumber,
                contactEmail,
                contactPhone,
                sn,
              });
              if (errors && Object.values(errors).length > 0) {
                setErrors(errors);
                setLoading(false);
              } else {
                if (userContext?.account) {
                  let urlImg = userContext?.profile?.photoUrl || "";
                  if (img !== undefined) {
                    urlImg = await saveImageToFirebase(config.firebase, userContext?.account?.userId, img, "main");
                  }
                  setLoading(true);
                  const response: IT.ResponseUpdateCoach = await SocialCoachSessionService.updateCoach(
                    userContext?.coach?.id!,
                    {
                      profile: {
                        phone: phoneNumber!,
                        birthday: birthday!,
                        firstName: firstName!,
                        lastName: lastName!,
                        photoUrl: urlImg,
                        socialNetworks: Object.values(sn),
                      },
                      coach: {
                        id: userContext?.coach?.id!,
                        companyName: company,
                        title,
                        contactEmail,
                        contactPhone,
                      },
                    },
                    userContext?.account?.userId!
                  );
                  let nextStep = userContext.signUpStep;
                  if (CoachSignUpStep[userContext?.signUpStep || 0] === CoachSignUpStep.COACH_DASHBOARD) {
                    await LoginStepServices.sendStep(CoachSignUpStep[CoachSignUpStep.COACH_BANK_INFORMATION]);
                    nextStep = CoachSignUpStep[CoachSignUpStep.COACH_BANK_INFORMATION];
                  }
                  if (response.profile["errors"]) {
                    setErrors({
                      firstName: response.profile["errors"][0].defaultMessage,
                      lastName: undefined,
                      birthday: undefined,
                      phoneNumber: undefined,
                    });
                    setSubmitting(false);
                  } else {
                    setSaved(true);
                    setTimeout(() => {
                      setSaved(false);
                    }, 1000);

                    let nextStepSection = {};
                    if (CoachSignUpStep[userContext!.signUpStep!] < CoachSignUpStep.COACH_BANK_INFORMATION) {
                      nextStepSection = { signUpStep: nextStep };
                    }
                    setUserContext({
                      ...userContext,
                      profile: {
                        ...userContext.profile,
                        ...response.profile,
                      },
                      coach: {
                        ...userContext.coach,
                        ...response.company,
                      },
                      ...nextStepSection,
                    });

                    if (!finishStep(userContext?.signUpStep)) {
                      onSuccess();
                    }
                  }
                }
              }
            } catch (e) {
              setErrors({
                firstName: String(e),
                lastName: undefined,
                birthday: undefined,
                phoneNumber: undefined,
              });
            }
            setLoading(false);
            setSubmitting(false);
          }}
        >
          {({ values, errors, handleChange, isSubmitting, handleSubmit, setFieldValue }) => (
            <Form>
              <Grid columns={1} className={"socialContent extraTopPadding"}>
                <Grid.Row columns={1}>
                  <Grid.Column width={12} className={"titleSectionContainer"}>
                    <Header as="h2">Coach Profile</Header>
                  </Grid.Column>
                  <Grid.Column computer={4} floated="right" className={"titleButton"}>
                    <Button
                      data-elm-id={`coachProfileSaveBtn`}
                      onClick={() => {
                        handleSubmit();
                      }}
                      type="submit"
                      disabled={isSubmitting}
                      className={"primary rounded"}
                    >
                      {finishStep(userContext?.signUpStep) ? "Save" : "Save & Next"}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Column>
                  <div className={"line"} />
                </Grid.Column>

                {saved && (
                  <Transition.Group as={Header} duration={300} animation={"drop"} size="tiny" verticalalign="middle">
                    <Message info content={"Saved!"} />
                  </Transition.Group>
                )}
                {errors && Object.values(errors).length > 0 && (
                  <Grid.Row columns={1}>
                    <Grid.Column width={12}>
                      <Message negative list={Object.values(errors) as string[]} />
                    </Grid.Column>
                  </Grid.Row>
                )}

                {loading && <Loader active size="large" />}

                <InternalGrid columns={2}>
                  <Grid.Column width={7} className={"profileColumn"}>
                    <ProfileInformation
                      onChangeImage={(file: File) => {
                        setImg(file);
                      }}
                      photoUrl={userContext?.profile?.photoUrl}
                      handleChange={handleChange}
                      handleSetFieldValue={setFieldValue}
                      firstName={values.firstName}
                      lastName={values.lastName}
                      title={values.title}
                      company={values.company}
                      birthday={values.birthday}
                      phoneNumber={values.phoneNumber}
                    />
                  </Grid.Column>
                  <Grid.Column width={8} className={"noPadding"} floated="right">
                    {/* <Grid.Row className={"profileSocialRow noMinHeight"}>
                      <ProfileSocialAccounts
                        type={"PROFILE"}
                        deleteEnable={true}
                        handleSocialNetworks={networks => {
                          setFieldValue("sn", Object.values(networks));
                        }}
                      />
                    </Grid.Row> */}
                    <Grid.Row className={"profileRow smallTop"}>
                      <CoachContactInformation
                        isSubmitting={loading}
                        contactEmail={values.contactEmail}
                        contactPhone={values.contactPhone}
                        handleChange={handleChange}
                      />
                    </Grid.Row>
                  </Grid.Column>
                </InternalGrid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const SignUpProfile = withRouter(CoachProfileFC);
