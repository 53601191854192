import { client as axios } from "./axiosClient";
import { FacebookPage, FacebookPages, InstagramAccountResponse, InstagramPage } from "../interfaces/dtos";

const GRAPH_API_VERSION = "v17.0";
const FB_ACCOUNTS = `https://graph.facebook.com/${GRAPH_API_VERSION}/me/accounts?limit=50&fields=access_token,name,name_with_location_descriptor,id&access_token=`;
const IG_ACCOUNT = `https://graph.facebook.com/${GRAPH_API_VERSION}/:pageId?fields=instagram_business_account{username}&access_token=:accessToken`;

export class FacebookServices {
  static graphApiVersion(): string {
    return GRAPH_API_VERSION;
  }

  static graphApiVersionNumber(): string {
    return GRAPH_API_VERSION.replace("v", "");
  }

  static async getPages(accessToken: number): Promise<FacebookPage[]> {
    const response = await axios.get<FacebookPages>(`${FB_ACCOUNTS}${accessToken}`);
    return response.data?.data || [];
  }

  static async getIGPages(accessToken: number): Promise<InstagramPage[]> {
    const pages = await this.getPages(accessToken);
    const igPages: InstagramPage[] = [];

    for (const page of pages) {
      try {
        const { data } = await axios.get<InstagramAccountResponse>(
          IG_ACCOUNT.replace(":pageId", page.id).replace(":accessToken", page.access_token)
        );
        const igAccount = data.instagram_business_account;
        if (igAccount?.id) {
          if (!igPages.some(a => a.instagramId === igAccount.id)) {
            igPages.push({
              ...page,
              instagramId: igAccount.id,
              instagramUsername: igAccount.username,
            });
          }
        }
      } catch (e: any) {
        console.log("Error attempting to get IG account info", e);
      }
    }

    return igPages;
  }
}
