import axios from "axios";
import { Constants } from "@kamino/services";
import { catchServiceException } from "@kamino/services";
import * as DTO from "../interfaces/dtos";

const CAMPAIGN_SERVICE = `${Constants.API_URL}/campaigns`;

export class CampaignServices {
  @catchServiceException()
  static async create(
    campaign: DTO.CampaignRequest,
    coachId: string
  ): Promise<DTO.CampaignResume> {
    const { data } = await axios.post<DTO.CampaignResume>(
      `${CAMPAIGN_SERVICE}`,
      {
        ...campaign,
        coachId,
      },
      {
        withCredentials: true,
      }
    );
    // .catch((reason: AxiosError) => {
    //   return reason.response;
    // });

    return data;
  }

  @catchServiceException()
  static async update(
    campaign: DTO.CampaignEditRequest,
    coachId: string
  ): Promise<DTO.CampaignResume> {
    const response = await axios.put<DTO.CampaignResume>(
      `${CAMPAIGN_SERVICE}/${campaign.campaignId}`,
      {
        ...campaign,
        coachId,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async delete(campaignId: string, coachId: string): Promise<string> {
    const response = await axios.patch<string>(
      `${CAMPAIGN_SERVICE}/${campaignId}/status/DELETED`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }

  @catchServiceException()
  static async get(campaignId: string): Promise<DTO.EditableCampaign> {
    const { data } = await axios.get<DTO.EditableCampaign>(
      `${CAMPAIGN_SERVICE}/${campaignId}`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async searchCampaigns(
    coachId: string,
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.CampaignResume>> {
    const customFilters = {
      searchTerm: filters?.title,
      categoryIds: filters?.status ? filters?.status?.length > 0 ? filters?.status : undefined : undefined,
    };

    let signals = {};
    if (filters?.abortController) {
      signals = {
        signal: filters?.abortController!!.signal,
      };
    }
    const response = await axios.post<DTO.Page<DTO.CampaignResume>>(
      `${CAMPAIGN_SERVICE}/coaches/${coachId}?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        ...signals,
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async getAll(
    coachId: string,
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 2000
  ): Promise<DTO.Page<DTO.CampaignResume>> {
    const customFilters = {
      searchTerm: filters?.title,
      categoryIds: filters?.status ? filters?.status?.length > 0 ? filters?.status : undefined : undefined,
    };

    const response = await axios.post<DTO.Page<DTO.CampaignResume>>(
      `${CAMPAIGN_SERVICE}/coaches/${coachId}?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async campaignsByCategory(
    coachId: string,
    categoryId?: string,
    page: number | string = 0,
    size: number = 5,
    sort: string = "createdDate,DESC",
    status: string[] = ["ACTIVE"]
  ): Promise<DTO.Page<DTO.CampaignResume>> {
    const feCategories = ["uncategorized", "unpublished", "pending"];
    const categoryIds =
      categoryId && feCategories.includes(categoryId)
        ? categoryId === "unpublished" || categoryId === "pending"
          ? null
          : [null]
        : [categoryId];

    const customFilters = {
      statuses:
        categoryId === "unpublished" || categoryId === "pending"
          ? [categoryId.toUpperCase()]
          : status,
      categoryIds,
      searchTerm: null,
    };
    const response = await axios.post<DTO.Page<DTO.CampaignResume>>(
      `${CAMPAIGN_SERVICE}/coaches/${coachId}?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async publicCampaignsByCategory(
    coachId: string,
    categoryId?: string,
    page: number | string = 0,
    size: number = 5,
    sort: string = "createdDate,DESC",
    status: string[] = ["ACTIVE"]
  ): Promise<DTO.Page<DTO.CampaignResume>> {
    const feCategories = ["uncategorized"];
    const customFilters = {
      categoryIds:
        categoryId && feCategories.includes(categoryId) ? [null] : [categoryId],
      searchTerm: null,
    };
    const response = await axios.post<DTO.Page<DTO.CampaignResume>>(
      `${CAMPAIGN_SERVICE}/coaches-public/${coachId}?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async subscribePlayers(
    campaignId: string,
    playerIds: string[]
  ): Promise<DTO.CampaignSubscriptionResponse> {
    const response = await axios.post<DTO.CampaignSubscriptionResponse>(
      `${CAMPAIGN_SERVICE}/${campaignId}/subscribe`,
      {
        playerIds,
      },
      {
        withCredentials: true,
      }
    );

    return response.data;
  }

  @catchServiceException()
  static async unSubscribePlayers(
    campaignId: string,
    playerIds: string[]
  ): Promise<DTO.CampaignSubscriptionResponse> {
    const response = await axios.post<DTO.CampaignSubscriptionResponse>(
      `${CAMPAIGN_SERVICE}/${campaignId}/unsubscribe`,
      {
        playerIds,
      },
      {
        withCredentials: true,
      }
    );

    return response.data;
  }

  @catchServiceException()
  static async statistics(
    coachId: string, // UNUSED
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 20
  ): Promise<DTO.Page<DTO.CampaignSubscribedStatistic[]>> {
    const campaignId = filters!.campaignId;
    const response = await axios.post<
      DTO.Page<DTO.CampaignSubscribedStatistic[]>
    >(
      `${CAMPAIGN_SERVICE}/statistics/${campaignId}?page=${page}&size=${size}&sort=${sort}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }

  @catchServiceException()
  static async playerCampaigns(
    playerId: number,
    page: number | string = 0,
    size: number = 20
  ): Promise<DTO.Page<DTO.PlayerCampaign>> {
    const response = await axios.post<DTO.Page<DTO.PlayerCampaign>>(
      `${CAMPAIGN_SERVICE}/players/${playerId}?page=${page}&size=${size}`,
      {
        subscriptionStatuses: ["SUBSCRIBED"],
      },
      {
        withCredentials: true,
      }
    );

    return response.data;
  }

  @catchServiceException()
  static async getForPlayers(
    coachId: string,
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 200
  ): Promise<DTO.Page<DTO.CampaignResume>> {
    const customFilters = {
      searchTerm: filters?.title,
      categoryIds: filters?.status ? filters?.status?.length > 0 ? filters?.status : undefined : undefined,
    };

    const response = await axios.post<DTO.Page<DTO.CampaignResume>>(
      `${CAMPAIGN_SERVICE}/coaches-public/${coachId}?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async subscribePlayerInCampaigns(
    playerId: number,
    campaignIds: string[]
  ): Promise<DTO.CampaignListSubscriptionResponse> {
    const response = await axios.post<DTO.CampaignListSubscriptionResponse>(
      `${CAMPAIGN_SERVICE}/subscribe-player/${playerId}`,
      {
        campaignIds,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }
}
