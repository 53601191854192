import { Media, MediaGroup, PlayerAccount, Post, SocialNetworkMessage, SocialNetworksId } from "..";

export const vpTimeKey = "preferredPostTimeUTC";
export const vpDateKey = "preferredPostDateUTC";

export enum VideoProjectStatus {
  SUBMITTED_FOR_EDITING = "SUBMITTED_FOR_EDITING",
  READY_TO_POST = "READY_TO_POST",
  SCHEDULED = "SCHEDULED",
  POSTED = "POSTED",
  POST_FAILED = "POST_FAILED",
}

export enum VideoProjectRoleType {
  PLAYER = "PLAYER",
  COACH = "COACH",
  EDITOR = "EDITOR",
}

export interface BaseVideoProject {
  videoProjectId?: number;
  videoScriptId?: number;
  coachId: string;
  playerId: number;
  createdDate?: string;
  status: VideoProjectStatus;
  statusLabel?: string;
  statusDate?: string;
  originalMedia?: MediaGroup;
  editedMedia?: MediaGroup;
  messages?: VideoProjectMessage[];
  editable?: boolean;
}

export interface VideoProjectWithPlayer extends VideoProject {
  playerAccount: PlayerAccount;
}
export interface VideoProject extends BaseVideoProject {
  postId?: number;
  preferredPostDate?: string;
  editedBy?: string;
  editedDate?: string;
  isTestProject?: boolean;
  isAutoPost?: boolean;
  post?: Post;
  autoCaption?: boolean;
  allowPlayerHashtags?: boolean;
  allowShortLink?: boolean;
  allowComplianceDisclaimer?: boolean;
}

export interface VideoProjectMessage {
  videoProjectMessageId: number;
  userId: number;
  userName: string;
  userRole: VideoProjectRoleType;
  content: string;
  createdDate: string;
}

export interface AkcElement {
  videoProjectAckId: number;
  ackType: "MESSAGE";
  ackFlag: boolean;
  ackDate: string;
  userRole: string;
}
export interface VideoProjectDetail extends BaseVideoProject {
  postId?: number;
  preferredPostDate?: string;
  postedDate?: string;
  editedBy?: string;
  editedDate?: string;
  isTestProject?: boolean;
  isAutoPost?: boolean;
  message: string;
  messageToPost?: string;
  socialNetworks?: SocialNetworksId[];
  autoCaption?: boolean;
  allowPlayerHashtags?: boolean;
  allowShortLink?: boolean;
  allowComplianceDisclaimer?: boolean;
  post?: Post;
  ackList?: AkcElement[];
}

export interface VideoProjectMessageRequest {
  content: string;
}
export interface VideoProjectRequest extends VideoProject {
  socialNetworkMessages: SocialNetworkMessage[];
  messageToPost: string;
  initialMessages?: VideoProjectMessageRequest[];
  originalMediaGroup: MediaGroup;
  editedMediaGroup?: MediaGroup;
  isAutoPost?: boolean;
}

export interface PlayerVideoProjectResponse extends BaseVideoProject {
  thumbnail?: Media;
  originalMedia?: MediaGroup;
  editedMedia?: MediaGroup;
  playerName?: string;
  companyName?: string;
  editedBy?: string;
  editedDate?: string;
  needsAck?: boolean;
  post?: Post;
}

export interface PlayerResumeResponse {
  fullName: string;
  playerId: number;
  userId: number;
}

export interface VideoProjectPlayerSummary {
  playerId: number;
  playerName: string;
  playerEmail: string;
  enabled: boolean;
  creditsRemaining: number;
  unlimitedCredits: boolean;
}

export interface VideoProjectUpdateRequest extends VideoProject {
  socialNetworkMessages: SocialNetworkMessage[];
  messageToPost: string;
  hashtags?: string;
  editedMediaGroup?: MediaGroup;
  isAutoPost?: boolean;
  autoCaption?: boolean;
}
