import { client as axios } from "./axiosClient";
import { Constants } from "@kamino/services";
import { catchServiceException } from "@kamino/services";
import * as DTO from "../interfaces/dtos";

export class ScheduleService {
 @catchServiceException()
  static async saveRandomWeeklySchedule(schedule: DTO.WeeklyScheduleRequest): Promise<DTO.WeeklyScheduleRequest> {
    const { data } = await axios.post<DTO.WeeklyScheduleRequest>(
      `${Constants.API_URL}/coaches/schedules/weekly`,
      {
        ...schedule,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

 @catchServiceException()
  static async updateRandomWeeklySchedule(
    scheduldId: number,
    schedule: DTO.WeeklyScheduleRequest
  ): Promise<DTO.WeeklyScheduleRequest> {
    const { data } = await axios.put<DTO.WeeklyScheduleRequest>(
      `${Constants.API_URL}/coaches/schedules/weekly/${scheduldId}`,
      {
        ...schedule,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getRandomWeeklySchedule(coachId: string, type: DTO.ScheduleType): Promise<DTO.WeeklyScheduleRequest> {
    const { data } = await axios.get<DTO.WeeklyScheduleRequest>(
      `${Constants.API_URL}/coaches/${coachId}/schedules/weekly/type/${type}`,
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
