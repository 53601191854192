import * as React from "react";
import * as DTO from "../../../../../interfaces";
import { Button, Grid, Icon, Image } from "semantic-ui-react";
import axios from "axios";
import { useMemo, useState } from "react";
import "./styles.scss";

interface OwnProps {
  videoProjectId: number;
  stickers: DTO.ComposedStickerResponse | undefined;
}

type Props = OwnProps;

/**
 * Prediction Components
 */
const StickersFC: React.FC<Props> = ({ videoProjectId, stickers }) => {
  const [collapsed, setCollapsed] = useState(true);
  const downloadFile = (filePath: string, fileName: string) => {
    axios.get(filePath, { responseType: "blob" }).then(response => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const mediaList = useMemo(() => {
    const list: DTO.Media[] = [];
    stickers?.playerSticker?.stickers?.media?.forEach(m => list.push(m));
    stickers?.coachSticker?.stickers?.media?.forEach(m => list.push(m));
    return list;
  }, [stickers?.playerSticker?.stickers?.media, stickers?.coachSticker?.stickers?.media]);

  return (
    <div className={"projectStickersContainer"}>
      {mediaList && mediaList.length > 0 && (
        <>
          <label>
            Stickers
            <Button
              basic
              content={collapsed ? `Show ${mediaList.length} stickers` : `Hide ${mediaList.length} stickers`}
              icon={collapsed ? "triangle right" : "triangle down"}
              className={"noBorder floatRight stickerHider"}
              labelPosition={"right"}
              onClick={() => setCollapsed(!collapsed)}
            />
          </label>

          <Grid>
            {!collapsed &&
              stickers?.playerSticker?.stickers &&
              mediaList.map((media, index) => {
                return (
                  <Grid.Column key={`sticker_container_${index}`} className={"stickerContainer"}>
                    <Image size={"tiny"} src={media?.thumbnailUri || media?.uri} />
                    <div
                      className="downloadIcon"
                      onClick={() =>
                        downloadFile(
                          media.uri!!,
                          `${videoProjectId}_STICKER_${media.category}_${index}.${media.extension}`
                        )
                      }
                    >
                      <Icon size={"large"} name="arrow alternate circle down outline" />
                    </div>
                  </Grid.Column>
                );
              })}
          </Grid>
        </>
      )}
    </div>
  );
};

export const Stickers = StickersFC;
