import * as React from "react";

import { Table, Header } from "semantic-ui-react";

import { SocialNetworksId, TotalStats } from "../../../../interfaces";

import "./styles.scss";

interface OwnProps {
  player: TotalStats;
  index: number;
  networkId: SocialNetworksId;
  keyId: string;
}

const SocialNetworkStatisticCellFC: React.FC<OwnProps> = ({ player, networkId, index, keyId }) => {
  const socialNames = { _FB: "Facebook", _IG: "Instagram", _LI: "LinkedIn", _TW: "X", _TT: "TikTok" };

  return (
    <Table.Row
      key={keyId}
      className={"socialLabelRow " + (index % 2 === 0 ? "socialLabelRowLight" : "socialLabelRowDark")}>
      <Table.Cell />
      <Table.Cell />
      <Table.Cell className="socialLabelCell">
        <Header as="h4">
          <Header.Content>{socialNames["_" + networkId]}</Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>{player?.followers || 0}</Table.Cell>
      <Table.Cell>{(player?.countPromptPosted && player?.countPromptPosted) || 0}</Table.Cell>
      <Table.Cell>{player?.likes || 0}</Table.Cell>
      <Table.Cell>{networkId === "TW" ? "-" : player?.comments || 0}</Table.Cell>
      {/* <Table.Cell>{views || 0}</Table.Cell> */}
      <Table.Cell>{networkId === "LI" ? "-" : player?.shares || 0}</Table.Cell>
      <Table.Cell>{player?.click || 0}</Table.Cell>
    </Table.Row>
  );
};
export const SocialNetworkStatisticCell = SocialNetworkStatisticCellFC;
