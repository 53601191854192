import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@kamino/services";

import { Page, AlertResponse, AlertType } from "../interfaces";

const ALERTS_SERVICE = `${Constants.API_URL}/alerts`;

export class AlertsServices {
  @catchServiceException()
  static async get(
    coachId: string,
    type: AlertType,
    page: number = 0,
    size: number = 30
  ): Promise<Page<AlertResponse>> {
    const { data } = await axios.post<Page<AlertResponse>>(
      `${ALERTS_SERVICE}/${coachId}?page=${page}&size=${size}&sort=createdDate,DESC`,
      {
        type,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async delete(alertId: number): Promise<boolean> {
    const response = await axios.delete<void>(`${ALERTS_SERVICE}/${alertId}`, {
      withCredentials: true,
    });

    return response.status === 204 || response.status === 200;
  }
}
