import * as React from "react";
import { Route, RouteProps, Redirect, RouteComponentProps } from "react-router";
import { RouteType } from "@kamino/services";
import { SessionContext } from "@kamino/ui";
import { Loader, LoaderProps } from "semantic-ui-react";
import { AppUserContext } from "../../providers";
import { UserGroups } from "../../interfaces";

interface Props extends RouteProps {
  routeType: RouteType;
  redirection?: {
    url: string;
    isExternal: boolean;
  };
  loaderComponent: React.FunctionComponent<LoaderProps> | JSX.Element;
  reservedGroups: string[];
}

export class SCSessionRoute extends Route<Props> {
  static defaultProps: Partial<Props> = {
    reservedGroups: [UserGroups.COACH, UserGroups.ADMIN, UserGroups.PLAYER, UserGroups.EDITOR],
    routeType: RouteType.PRIVATE_ROUTE,
    loaderComponent: <Loader active size="large" />,
  };

  shouldRenderExpectedRoute = (userContext?: AppUserContext): boolean => {
    const { routeType, reservedGroups, redirection } = this.props;
    let finalG: string[] = [];
    if (userContext) {
      finalG = userContext!.account.scGroups!;
    }
    const shouldRender =
      (routeType === RouteType.PRIVATE_ROUTE && !!userContext && reservedGroups.includes(finalG[0])) ||
      (routeType === RouteType.PUBLIC_ROUTE && !userContext);

    if (redirection && redirection.isExternal && !shouldRender && window) {
      window.location.assign(redirection.url);
    }

    return shouldRender;
  };

  renderComponent = (props: RouteComponentProps) => {
    const Component = this.props.component as React.ComponentType<any>;
    return <Component {...props} />;
  };

  render() {
    const { routeType, redirection, render, component: _, loaderComponent, ...rest } = this.props;

    return (
      <SessionContext.Consumer>
        {({ userContext, loading }) => {
          if (loading && loaderComponent) {
            return <>{loaderComponent}</>;
          }

          return this.shouldRenderExpectedRoute(userContext) ? (
            <Route {...rest} render={this.renderComponent} />
          ) : (
            <Route
              {...rest}
              render={props => (
                <Redirect
                  exact
                  to={{
                    pathname: redirection ? redirection.url : "/",
                    state: { from: props.location },
                  }}
                />
              )}
            />
          );
        }}
      </SessionContext.Consumer>
    );
  }
}
