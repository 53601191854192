import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@kamino/services";
import * as DTO from "../interfaces";

const TIKTOK_URL = `${Constants.API_URL}/players`;
const QUERY_INFO = `${TIKTOK_URL}/query-information/:playerId`;
const PREFERENCES = `${TIKTOK_URL}/tiktok-preferences`;

export class TiktokServices {
  @catchServiceException()
  static async queryInfo(
    playerId: number
  ): Promise<DTO.TiktokQueryInfoResponse> {
    const result = await axios.get<DTO.TiktokQueryInfoResponse>(
      `${QUERY_INFO.replace(":playerId", `${playerId}`)}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  }

  @catchServiceException()
  static async tiktokPreferences(): Promise<DTO.TiktokPreferences> {
    const result = await axios.get<DTO.TiktokPreferences>(
      PREFERENCES,
      {
        withCredentials: true,
      }
    );

    return result.data;
  }

  @catchServiceException()
  static async updateTiktokPreferences(
    preferences: DTO.TiktokPreferences
  ): Promise<void> {
    const result = await axios.put<void>(
      PREFERENCES,
      {
        ...preferences,
      },
      {
        withCredentials: true,
      }
    );
    return result.data;
  }
}
