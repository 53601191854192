import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@kamino/services";
import * as DTO from "../interfaces/dtos";
import { AppUserContext } from "../providers";

export class UsersService {
  @catchServiceException()
  static async login(username: string, password: string): Promise<any> {
    const response = await axios.post<any>(`${Constants.API_URL}/auth/login`, {
      username,
      password,
    });
    return response.data;
  }

  @catchServiceException()
  static async logout(): Promise<void> {
    await axios.post<void>(`${Constants.API_URL}/auth/logout`, null, {
      withCredentials: true,
    });
    return;
  }

  @catchServiceException()
  static async verifyAccount(userId: string, actionToken: string): Promise<void> {
    await axios.put<void>(
      `${Constants.API_URL}/accounts/verify`,
      {
        userId,
        token: actionToken,
      },
      {
        withCredentials: false,
      }
    );
  }

  @catchServiceException()
  static async getUserContext(): Promise<AppUserContext> {
    const response = await axios.get<AppUserContext>(`${Constants.API_URL}/accounts/me`, {
      withCredentials: true,
    });
    return response.data;
  }

  @catchServiceException()
  static async searchAccounts(
    query: DTO.SearchAccountsQuery,
    page: number,
    size?: number
  ): Promise<DTO.Page<DTO.CompleteAccount>> {
    const { data } = await axios.post<DTO.Page<DTO.CompleteAccount>>(`${Constants.API_URL}/accounts/search`, query, {
      withCredentials: true,
      params: {
        page,
        size,
      },
    });
    return data;
  }

  @catchServiceException()
  static async searchCoachesStatistics(
    coachesIds: string[] | undefined[],
    reportsType?: DTO.ReportType
  ): Promise<DTO.CoachStatistic[]> {
    const { data } = await axios.post<DTO.CoachStatistic[]>(
      `${Constants.API_URL}/coaches/statistics/search`,
      {
        coachIds: coachesIds,
        reportsType: reportsType ?? ["PROMPT_ENGAGEMENT_REPORT", "PLAYERS_REPORT"],
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async adminAuthenticateCoachEmail(userId: number): Promise<boolean> {
    const response = await axios.put<DTO.CoachStatistic[]>(`${Constants.API_URL}/accounts/users/${userId}/ACTIVE`, "", {
      withCredentials: true,
    });

    return response ? true : false;
  }

  // FOR ADMINS
  @catchServiceException()
  static async updateUserCredentials(userId: number, credentials: DTO.UpdateCredentialsRequest): Promise<any> {
    const response = await axios.put<any>(
      `${Constants.API_URL}/accounts/users/${userId}/credentials`,
      {
        ...credentials,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  // FOR ADMINS
  @catchServiceException()
  static async setDemoAccount(userId: number, isDemo: boolean): Promise<void> {
    await axios.put<void>(
      `${Constants.API_URL}/accounts/users/${userId}/demo`,
      {
        isDemo,
      },
      {
        withCredentials: false,
      }
    );
  }

  @catchServiceException()
  static async updateUserGroup(user: any): Promise<DTO.CoachingProfileData> {
    const { data } = await axios.put<DTO.CoachingProfileData>(
      `${Constants.API_URL}/accounts/user-group`,
      {
        ...user,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
