import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Button, Message } from "semantic-ui-react";
import _, { get } from "lodash";

import { SideBar, TitleSection, LibraryListContent, ButtonLink } from "../../../components";
import * as DTO from "../../../interfaces";
import { AppContext } from "../../../providers";
import { coachWithWritePermission, URLS } from "../../../utils";
import { CoachSignUpStep, LoginStepServices, PromptServices } from "../../../services";

import { UploadModal } from "./components/UploadModal";

import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";
import {
  RowContainer,
  LoadingSection,
  PromptUploadingSection,
  CompleteIcon,
  PromptLoader,
  LoadingSectionTitle,
  PromptTitle,
  ErrorIcon,
} from "./styled";

import { LibraryType, descriptors } from "./descriptors";
import "./styles.scss";
import { PlayerLibraryListContent } from "../../../components/Lists/PlayerLibraryListContent";

type Props = RouteComponentProps;

const LibraryFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);

  const playerId = get(userContext, "player.id", undefined);
  const coachId = userContext?.subscriptions!![0].pricePlan.coachId;

  const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[LibraryType.title] })}
            ready={true}
            showNextOption={false}
            showLinkOption={false}
            titleSize={5}
          />
          <RowContainer>
            <SocialGrid.Column>
              <PlayerLibraryListContent
                data-elm-id={"playerLibraryListContent"}
                reloadPrompts={refresh}
                coachId={coachId!}
                playerId={playerId!!}
                isEmptySection={setIsEmpty}
              />
            </SocialGrid.Column>
          </RowContainer>
        </SocialGrid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const PlayerLibrary = withRouter(LibraryFC);
