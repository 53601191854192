import * as React from "react";
import{ useIntl } from "react-intl";

import * as DTO from "../../interfaces";
import { VideoCatalystListComponent } from "../Lists";
interface OwnProps {
  players: DTO.PlayerResumeResponse[];
  coachId: string;
}

type Props = OwnProps

export const VideoCatalystListByPlayer = ({ players, coachId }: Props) => {
  return (
    <div className="promptListContainer">
      {players.map(player => {
        return (
          <VideoCatalystListComponent
            coachId={coachId}
            key={"video_catalyst_list_c_" + player.playerId}
            player={player}
            showError={error => {
              alert(error);
            }}
          />
        );
      })}
    </div>
  );
}
