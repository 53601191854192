import { client as axios } from "./axiosClient";
import { Constants } from "@kamino/services";

const REPORT_SERVICE = `${Constants.API_URL}/summary-report`;

export class ReportServices {
  static async sendCoachReport(coachId: string): Promise<void> {
    const response = await axios.post<void>(`${REPORT_SERVICE}/coach/${coachId}`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async sendPlayerReport(playerId: number): Promise<void> {
    const response = await axios.post<void>(`${REPORT_SERVICE}/player/${playerId}`, {
      withCredentials: true,
    });
    return response.data;
  }
}
