import * as React from "react";

import * as DTO from "../../../interfaces";
import { URLS } from "../../../utils";

import { VideoScriptCardStyle, VideoScriptDescription, VideoScriptHeader, VideoScriptMeta } from "./styled";

import "./styles.scss";
import { Label } from "semantic-ui-react";
import { Category } from "../../../interfaces";

interface OwnProps {
  videoScript: DTO.VideoScript;
}

type Props = OwnProps;

const VideoScriptCardFC: React.FC<Props> = ({ videoScript: { videoScriptId, title, content, categories } }: Props) => {
  const scrollToCategory = (e: React.MouseEvent, category: Category) => {
    e.stopPropagation();
    e.preventDefault();
    document.querySelector(`#cat${category.categoryId}`)?.scrollIntoView();
  };

  return (
    <VideoScriptCardStyle className={"videoScriptCard"} href={URLS.videoScript.replace(":id", `${videoScriptId}`)}>
      <VideoScriptCardStyle.Content>
        <VideoScriptHeader>{title}</VideoScriptHeader>
        <VideoScriptMeta>
          {categories.map(category => {
            return (
              <Label
                key={`cat-in-card-${videoScriptId}-${category.categoryId}`}
                onClick={e => scrollToCategory(e, category)}
              >
                {category.name}
              </Label>
            );
          })}
        </VideoScriptMeta>
        <VideoScriptDescription>{content}</VideoScriptDescription>
      </VideoScriptCardStyle.Content>
    </VideoScriptCardStyle>
  );
};

export const VideoScriptCard = VideoScriptCardFC;
