import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Card, CardContent, CardDescription, CardHeader, CardMeta, Grid } from "semantic-ui-react";

import { MiniCard } from "../../../../../../../components";
import { PlayerQuickAction, ActionStyleLoweCase } from "../../../../../../../interfaces";

import { URLS } from "../../../../../../../utils";

import "./styles.scss";

interface OwnProps {
  action: PlayerQuickAction;
}
type Props = OwnProps & RouteComponentProps;

const QuickActionsFC: React.FC<Props> = ({ action, history }) => {
  const { title, subtitle, actionStyle, actionTarget } = action;

  React.useEffect(() => {}, []);

  const cardPressed = (): void => {
    const routesMapper = {
      CUSTOMIZATIONS: { name: URLS.player.customizations },
      SOCIAL_NETWORKS: { name: URLS.player.socialAccounts },
      PROMPT_LIBRARY: { name: URLS.player.library },
    };
    history.push(routesMapper[actionTarget].name);
  };

  const actionStyleLC: "default" | "urgent" | "premium" = actionStyle.toLocaleLowerCase() as ActionStyleLoweCase;

  return (
    <Card onClick={cardPressed} className={"cardBorder_" + actionStyleLC}>
      <CardContent>
        <CardHeader>{title}</CardHeader>
        <br /> <br />
        <CardDescription className={"quickActionSubtitle"} content={subtitle} />
      </CardContent>
    </Card>
  );
};

export const QuickActionCard = withRouter(QuickActionsFC);
