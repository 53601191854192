import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Header, Grid, Form, Message, Loader, Transition } from "semantic-ui-react";

import { get } from "lodash";
import { Formik } from "formik";
import { UserContext } from "@kamino/services";

import { DownloadAppInformation, PlayerProfileInformation, TitleSection, SideBar } from "../../../components";

import { validationProfileSchema } from "./validations";

import { AppContext } from "../../../providers";
import { ConfigContext } from "../../../contexts/appContexts";
import * as IT from "../../../interfaces";
import { PlayerSignUpStep, PlayersServices, SocialCoachSessionService } from "../../../services";
import { BEDate, redirectToPlayerStep, saveImageToFirebase, userFullName } from "../../../utils";

import { WhiteContainer, ContentGrid, InternalGrid } from "../../../styling/baseStyle";
import "./styles.scss";

interface OwnProps {
  successHandler: (user: UserContext) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedPlayerProfile: React.FC<Props> = ({ history }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const config: IT.ConfigProperties = React.useContext(ConfigContext);

  const questionsValues = config.questions
    ? config.questions.reduce(
        (acc, question) => ((acc[question.key] = get(userContext, "player." + question.key, "")), acc),
        {}
      )
    : [];
  const questionsNames = config.questions
    ? config.questions.reduce((acc, question) => ((acc[question.key] = question.contentDescriptor), acc), {})
    : [];

  const [playerPicture, setPlayerPicture] = React.useState<File | undefined>();
  const [saved, setSaved] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (userContext?.signUpStep! !== PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      const url = redirectToPlayerStep(userContext?.signUpStep!);
      window.location.assign(url);
    }
  }, [userContext]);

  const savedBanner = () => {
    return (
      saved && (
        <Transition.Group as={Header} duration={300} animation={"drop"} size="tiny" verticalalign="middle">
          <Message info content={"Saved!"} />
        </Transition.Group>
      )
    );
  };

  const errorBanner = (errors: any) => {
    return (
      errors &&
      Object.values(errors).length > 0 &&
      !loading && (
        <Grid.Row columns={1}>
          <Grid.Column width={12}>
            <Message negative list={Object.values(errors) as string[]} />
          </Grid.Column>
        </Grid.Row>
      )
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid>
        <Formik
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
          validateOnMount={true}
          initialValues={{
            firstName: get(userContext, "profile.firstName", ""),
            lastName: get(userContext, "profile.lastName", ""),
            title: get(userContext, "player.webAddress", ""),
            birthday: get(userContext, "profile.birthday", ""),
            phoneNumber: get(userContext, "profile.phone", ""),
            photoUrl: get(userContext, "profile.photoUrl", undefined),
            transactionsClosedLastYear: get(userContext, "player.transactionsClosedLastYear", undefined),
            averageIncomesPerTransaction: get(userContext, "player.averageIncomesPerTransaction", undefined),
            leadsNeedToCloseTransaction: get(userContext, "player.leadsNeedToCloseTransaction", undefined),
          }}
          validationSchema={validationProfileSchema(questionsValues, config.questionsLimits, questionsNames)}
          onSubmit={async (
            {
              firstName,
              lastName,
              title,
              birthday,
              phoneNumber,
              leadsNeedToCloseTransaction,
              averageIncomesPerTransaction,
              transactionsClosedLastYear,
            },
            { setSubmitting, setErrors, validateForm }
          ) => {
            try {
              const errors = await validateForm({
                firstName,
                lastName,
                title,
                birthday,
                phoneNumber,
                transactionsClosedLastYear,
                leadsNeedToCloseTransaction,
                averageIncomesPerTransaction,
              });
              if (errors && Object.values(errors).length > 0) {
                setErrors(errors);
                setLoading(false);
              } else {
                if (userContext?.account && userContext?.player?.id) {
                  const { player } = userContext!;

                  setLoading(true);

                  let playerPictureUrl = userContext?.profile?.photoUrl || "";
                  if (playerPicture !== undefined) {
                    playerPictureUrl = await saveImageToFirebase(
                      config.firebase,
                      userContext?.account?.userId,
                      playerPicture,
                      "main"
                    );
                  }

                  const accountSectionRequest = {
                    ...player!,
                    transactionsClosedLastYear,
                    averageIncomesPerTransaction,
                    leadsNeedToCloseTransaction,
                    hashtags: null,
                  };

                  const playerUpdateRequest: IT.UpdateUserPayload = {
                    ...userContext?.profile,
                    phone: phoneNumber!,
                    birthday: BEDate(birthday),
                    firstName: firstName!,
                    lastName: lastName!,
                    photoUrl: playerPictureUrl,
                  };

                  const updateProfile = SocialCoachSessionService.updatePlayer(playerUpdateRequest);
                  const updateAccount = PlayersServices.updatePlayerQuestions(
                    userContext?.player?.id!,
                    accountSectionRequest
                  );

                  Promise.all([updateProfile, updateAccount])
                    .then(() => {
                      setSaved(true);
                      setTimeout(() => {
                        setSaved(false);
                        setUserContext({
                          ...userContext!,
                          profile: {
                            ...userContext!.profile!,
                            ...playerUpdateRequest,
                          },
                          player: {
                            ...player!,
                            transactionsClosedLastYear,
                            averageIncomesPerTransaction,
                            leadsNeedToCloseTransaction,
                          },
                        });
                      }, 1000);
                    })
                    .catch(e => {
                      const errorMessage = String(e);
                      setErrors({
                        firstName: errorMessage,
                        lastName: undefined,
                        birthday: undefined,
                        phoneNumber: undefined,
                      });
                    })
                    .finally(() => {
                      setLoading(false);
                      setSubmitting(false);
                    });
                }
              }
            } catch (e) {
              setErrors({
                firstName: String(e),
                lastName: undefined,
                birthday: undefined,
                phoneNumber: undefined,
              });
            }
          }}
        >
          {({ values, errors, handleChange, isSubmitting, setFieldValue, handleSubmit }) => (
            <Form className={isSubmitting ? "disabledForm" : ""} error>
              <Grid columns={1} className={"socialContent"}>
                <TitleSection
                  title={`Hello, ${userFullName(userContext!.profile!)}`}
                  ready={true}
                  showLoading={true}
                  isLoading={loading}
                  disableDuringLoading={true}
                  showNextOption={true}
                  customNextLabel={"Save"}
                  showLinkOption={false}
                  handleNextAction={() => handleSubmit()}
                />

                {savedBanner()}
                {errorBanner(errors)}

                <InternalGrid columns={1} centered className={"coachBaseContainer"}>
                  {/* Left Section */}
                  <PlayerProfileInformation
                    onChangeImage={(file: File) => {
                      setPlayerPicture(file);
                    }}
                    handleChange={handleChange}
                    handleSetFieldValue={setFieldValue}
                    values={values}
                  />
                </InternalGrid>
                <DownloadAppInformation />
              </Grid>
            </Form>
          )}
        </Formik>
      </ContentGrid>
      {loading && <Loader active size="large" />}
    </WhiteContainer>
  );
};

export const PlayerProfile = withRouter(IntegratedPlayerProfile);
