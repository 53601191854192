import * as React from "react";
import { Button } from "semantic-ui-react";
import { Upload } from "antd";

import "../../styling/antd.scss";

import { createVideoThumbnailBlob, saveElementToFirebaseCancellable } from "../../utils";
import { ConfigContext } from "../../contexts/appContexts";
import { SocialFiles } from "../../interfaces";

import { BoxDescription, UploadButtonColumn, UploadGrid } from "./styled";

interface OwnProps {
  onChangeImage: (file: SocialFiles) => void;
  fileName: string;
  title: string;
  customMessage?: string;
  nodeName?: string;
  accept?: string;
  placeholder?: string;
  multiple?: boolean;
}

type Props = OwnProps;

export const UploadMultipleElements: React.FC<Props> = ({
  nodeName = "bulk",
  accept = "image/png, image/jpeg, video/mp4",
  customMessage = "Drag and drop your files here to upload",
  fileName,
  title,
  multiple = true,
  onChangeImage,
}) => {
  const { firebase: firebaseConfig } = React.useContext(ConfigContext);

  const props = {
    name: "file",
    multiple,
    showUploadList: false,
  };

  const timestamp = new Date().getTime();
  const fileNode = `${fileName}/${nodeName}${timestamp}`;
  return (
    <div>
      <Upload
        {...props}
        data-elm-id="fileElementUpload"
        openFileDialogOnClick={true}
        withCredentials={true}
        accept={accept}
        action={async file => {
          const scFile: SocialFiles = file;
          const firebasePromise = saveElementToFirebaseCancellable(firebaseConfig, fileNode, file, `${file.uid}`);
          const mediaType = file.type.includes("video") ? "VIDEO" : "IMAGE";
          const thumbnail = URL.createObjectURL(file);
          const urlPromise = mediaType === "IMAGE" ? Promise.resolve(thumbnail) : createVideoThumbnailBlob(thumbnail);
          urlPromise.then(url => {
            scFile.thumbnail = url;
            scFile.uploadTask = firebasePromise;
            firebasePromise.on(
              "state_changed",
              snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              error => {
                // console.log('Upload error ', error);
              }
            );
            onChangeImage(scFile);
          });
          //  });

          return "";
        }}>
        <BoxDescription>{customMessage}</BoxDescription>
        <UploadGrid centered columns={4}>
          <UploadButtonColumn className={"centered"}>
            <Button
              data-elm-id={`uploadModal${title.replace(" ", "")}Btn`}
              className={"primary centered"}
              type="submit">
              {title}
            </Button>
          </UploadButtonColumn>
        </UploadGrid>
      </Upload>
    </div>
  );
};
