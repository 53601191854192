import * as React from "react";
import { Image } from "semantic-ui-react";

import * as DTO from "../../../interfaces";
import { printDateTimePST, URLS } from "../../../utils";

import { Svg } from "../../../assets";
import { ThumbnailPreview, VideoProjectCardContentStyle, VideoProjectCardStyle, VideoProjectHeader } from "./styled";

import "./styles.scss";

interface OwnProps {
  videoProject: DTO.PlayerVideoProjectResponse;
}

type Props = OwnProps;

const VideoProjectCardFC: React.FC<Props> = ({
  videoProject: { videoProjectId, thumbnail, statusLabel, statusDate, needsAck },
}: Props) => {
  return (
    <VideoProjectCardStyle
      className={needsAck ? "videoProjectCard needsAck" : "videoProjectCard"}
      href={URLS.coach.videoProject.replace(":id", `${videoProjectId}`)}
    >
      <Image className={"reelsIcon"} size="small" src={Svg.Reels} />
      <ThumbnailPreview width={3} thumbnailurl={thumbnail && thumbnail.thumbnailUri} />
      <VideoProjectCardContentStyle>
        <VideoProjectHeader>{statusLabel}</VideoProjectHeader>
        <VideoProjectCardStyle.Description>{printDateTimePST(statusDate)}</VideoProjectCardStyle.Description>
      </VideoProjectCardContentStyle>
    </VideoProjectCardStyle>
  );
};

export const VideoProjectCard = VideoProjectCardFC;
