import React, { useMemo, useState } from "react";
import { Button, Grid } from "semantic-ui-react";

import * as DTO from "../../../interfaces";
import { VideoScriptCard } from "../VideoScriptCard";

import "./styles.scss";
interface OwnProps {
  videoScriptGroup: DTO.CategorizedVideoScripts;
}

type Props = OwnProps;

const VideoScriptGroupFC: React.FC<Props> = ({ videoScriptGroup: { category, scripts } }: Props) => {
  const [displayAll, setDisplayAll] = useState(false);

  const displayedScripts = useMemo(() => {
    if (displayAll) {
      return scripts;
    } else {
      return scripts.slice(0, 4);
    }
  }, [scripts, displayAll]);

  const displayLabel = useMemo(() => {
    if (displayAll || scripts.length <= 3) {
      return `Showing all ${scripts.length}`;
    } else {
      return `Showing 4 of ${scripts.length}`;
    }
  }, [displayAll, scripts.length]);

  return (
    <>
      <Grid.Row>
        <Grid.Column width={12} floated="left" className={"subSectionTitle"}>
          <label id={`cat${category.categoryId}`}>{category.name}</label>
        </Grid.Column>
        <Grid.Column width={4} floated="right" className={""}>
          <Button
            basic
            content={displayLabel}
            icon={!displayAll ? "triangle right" : "triangle down"}
            className={"noBorder"}
            labelPosition={"right"}
            onClick={() => setDisplayAll(!displayAll)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <div key={category.categoryId} className={"scriptCategory"}>
          {displayedScripts.map(videoScript => {
            return <VideoScriptCard key={"video_script_card_" + videoScript.videoScriptId} videoScript={videoScript} />;
          })}
        </div>
      </Grid.Row>
    </>
  );
};

export const VideoScriptGroup = VideoScriptGroupFC;
