import { client as axios } from "./axiosClient";
import { Constants } from "@kamino/services";
import { catchServiceException } from "@kamino/services";
import * as DTO from "../interfaces/dtos";
const getLandingPagesPath = (coachId: string) => `/coaches/${coachId}/landing-pages`;

export class CoachingProfileService {
  @catchServiceException()
  static async savePreview(
    coachId: string,
    coachingProfile: DTO.CoachingProfileData
  ): Promise<DTO.CoachingProfileData> {
    const { data } = await axios.put<DTO.CoachingProfileData>(
      `${Constants.API_URL}${getLandingPagesPath(coachId)}/preview`,
      {
        ...coachingProfile,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async saveFinal(coachId: string, coachingProfile: DTO.CoachingProfileData): Promise<DTO.CoachingProfileData> {
    const { data } = await axios.put<DTO.CoachingProfileData>(
      `${Constants.API_URL}${getLandingPagesPath(coachId)}/final`,
      {
        ...coachingProfile,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getPreview(coachId: string): Promise<DTO.CoachingProfileData> {
    const { data } = await axios.get<DTO.CoachingProfileData>(
      `${Constants.API_URL}${getLandingPagesPath(coachId)}/preview`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getFinal(coachId: string): Promise<DTO.CoachingProfileData> {
    const { data } = await axios.get<DTO.CoachingProfileData>(
      `${Constants.API_URL}${getLandingPagesPath(coachId)}/final`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

 @catchServiceException()
  static async landingPagePublic(shareLink: string): Promise<DTO.LandingResponse> {
    const response = await axios.get<DTO.LandingResponse>(
      `${Constants.API_URL}/coaches/public-landing-pages/${shareLink}`,
      {
        withCredentials: false,
      }
    );
    return response.data;
  }
 @catchServiceException()
  static async landingPagePublicById(userId: string): Promise<DTO.LandingResponse> {
    const response = await axios.get<DTO.LandingResponse>(
      `${Constants.API_URL}/coaches/public-landing-pages-by-id/${userId}`,
      {
        withCredentials: false,
      }
    );
    return response.data;
  }

 @catchServiceException()
  static async coachPublicInfoByCoachId(coachId: string): Promise<DTO.CoachBasic> {
    const response = await axios.get<DTO.CoachBasic>(
      `${Constants.API_URL}/accounts/coach/${coachId}/public`,
      {
        withCredentials: false,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async updateCoachType(coachId: string, type: "BASIC" | "ENTERPRISE"): Promise<DTO.CoachingProfileData> {
    const { data } = await axios.put<DTO.CoachingProfileData>(
      `${Constants.API_URL}/accounts/coaches/${coachId}/types/${type}`,
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
