import styled from "styled-components";
import { Form, Grid } from "semantic-ui-react";

import { theme } from "./theme";

export const headerSize = (customurl?: string): string => {
  if (customurl) {
    return `url("${customurl}") no-repeat center top`;
  }

  return `transparent`;
};

export const WhiteContainer = styled.div`
  background-color: #fff;
  display: flex;
  min-height: 100vh;
`;

export const BasicWhiteContainer = styled.div`
  padding: 35px;
  background-color: #fff;
  text-align: center;
`;

export const TransparentContainer = styled.div`
  background-color: transparent;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
`;

export const FloatedContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 2.5rem;
`;

export const BasicContainer = styled.div`
  padding-bottom: 40px;
`;

export const ContentGridLimits = styled(Grid)`
  max-width: 1300px;
  background-color: #fff;
  @media (max-width: 1600px) {
    max-width: 83%;
  }

  @media (max-width: 1450px) {
    max-width: 90%;
  }
`;

export const ContentGrid = styled(ContentGridLimits)({
  display: "flex!important",
  marginTop: "0!important",
  marginLeft: "0!important",
  width: "100%",
  padding: "60px 73px 60px 41px !important",
  flexWrap: "wrap",
});

export const TabContentGrid = styled(Grid)({
  backgroundColor: "#fff",
  display: "flex !important",
  marginTop: "0px !important",
  marginLeft: "0px !important",
  width: "100%",
  padding: "0px !important",
  flexWrap: "wrap",
});

export const SegmentedGrid = styled(Grid)({
  backgroundColor: `${theme.backgroundColor}`,
  display: "flex!important",
  marginTop: "0!important",
  marginLeft: "0!important",
  width: "100%",
  flexWrap: "wrap",
});

export const ContentGridSection = styled(Grid)({
  paddingTop: "20px !important",
  paddingLeft: "40px !important",
  marginLeft: "0 !important",
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  // overflowX: "scroll",

  "@media (max-width: 1600px)": {
    maxWidth: "83%",
  },

  "@media (max-width: 1550px)": {
    maxWidth: "90%",
  },

  "@media (max-width: 1450px)": {
    maxWidth: "90%",
  },
});

export const SocialGrid = styled(Grid)`
  padding-left: ${(props: any) => (props.leftpadding ? theme.size[props.leftpadding] + "px" : "inherit")};
`;

export const SocialGridWithPadding = styled(Grid)`
  padding-bottom: ${(props: any) => (props.bottom ? props.bottom + "px" : "inherit")};
`;
export const InternalPaddingGrid = styled(Grid)({
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  marginLeft: "37px !important",
});

export const InternalGrid = styled(Grid)({
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  marginLeft: "0 !important",
  overflow: "hidden",
});

export const SmallGrid = styled(Grid)({
  paddingTop: "20px !important",
  minHeight: "90px",
});

export const SCSubtitle = styled.p`
  color: ${theme.lineColor};
  font-family: "Open Sans", sans-serif;
  font-size: 1.15em;
  letter-spacing: -0.24px;
  line-height: 21.79px;
  text-align: left;
  white-space: pre-line;
`;

export const ColumnBasicPadding = styled(Grid.Column)({
  padding: "15px 0 !important",
});

export const RowBasicPadding = styled(Grid.Row)({
  paddingBottom: "15px !important",
});

export const SocialBasicRow = styled(Grid.Row)({
  padding: "0px !important",
});

export const SocialRowGrid = styled(Grid.Row)({
  paddingBottom: "20px !important",
  paddingLeft: "15px",
});

export const RowMediumPadding = styled(Grid.Row)({
  paddingBottom: "20px !important",
});

export const GridNoMargin = styled(Grid)({
  marginTop: "0px !important",
});

export const RowNoPadding = styled(Grid.Row)({
  padding: "0px 0px 5px 0px !important",
});
export const BlueTag = styled.span`
  background: ${theme.buttonColor};
  color: white;
  font-family: "Open Sans", sans-serif;
  float: left;
  height: 25px;
  border-radius: 5px;
  padding: 1px 8px;
  margin: 3px 5px 0px 10px;
`;

export const TitleLabel = styled.label`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
`;

export const SCFormField = styled(Form.Field)({
  marginTop: "35px !important",
});

export const BrandHeaderImage = styled(Grid.Column)`
  border-bottom: 3px solid black;
  color: #fff;
  padding: 30px;
  height: 75px;
  background: ${props => headerSize(props.customurl)};
  background-size: ${props => (props.thumbnailurl ? "cover" : "contain")};
  background-color: white;
`;
// #eaeaf0;
