import * as React from "react";

import { CoachSignUpStep } from "../../services";
import { CoachComponent, MenuElement, SubscriptionType, UserGroups } from "../../interfaces";
import { AppContext } from "../../providers";
import { availablePermission, URLS } from "../../utils";
import { SideBarBase } from "../SideBarBase";

import { playerWebOptions, webComponents } from "./components";
import "./styles.scss";
import useAdminCoachSettings from "../AppSettingsTab/adminSettingsHook";

interface Props {
  history: any;
  readyToContinue?: boolean;
}

const permissions: CoachComponent[] = [
  {
    componentId: "PROFILE",
    name: "Company Info",
  },
  {
    componentId: "PRICE_PLAN",
    name: "Plan",
  },
  {
    componentId: "MARKETING",
    name: "Marketing Site",
  },
  {
    componentId: "LIBRARY",
    name: "LIBRARY",
  },
  {
    componentId: "CAMPAIGNS",
    name: "Campaigns",
  },
  {
    componentId: "VIDEO_CATALYST",
    name: "Video Catalyst",
  },
  {
    componentId: "MANAGE_SCRIPT_LIBRARY",
    name: "Script Library",
  },
  {
    componentId: "PLAYERS",
    name: "Player",
  },
  {
    componentId: "COMPLIANCE",
    name: "Compliance Manager",
  },
  {
    componentId: "ALERTS",
    name: "ALERTS",
  },
  {
    componentId: "ADMIN",
    name: "ADMIN",
  },
  {
    componentId: "DIGEST_EMAIL",
    name: "DIGEST_EMAIL",
  },
  {
    componentId: "APP_SETTINGS",
    name: "APP_SETTINGS",
  },
];

const SideBarFC: React.FC<Props> = ({ readyToContinue = true, history }) => {
  const { userContext } = React.useContext(AppContext);
  const basicCoach = userContext?.coach?.type && userContext?.coach?.type === "BASIC";
  const isPlayer = userContext?.player;

  const isEditor = userContext?.account?.scGroups?.some(group => group === UserGroups.EDITOR);
  const coachType = userContext?.coach?.type;

  const { displayAdminSettings } = useAdminCoachSettings(userContext?.coach?.id);

  const playersOptions = (): MenuElement[] => {
    const basicPlayer = userContext?.subscriptions && userContext?.subscriptions[0].type === SubscriptionType.SINGLE;

    const options = [...playerWebOptions];

    if (basicPlayer) {
      options.push({
        title: "Payment Information",
        path: URLS.player.paymentInfo,
      });
    }

    return options;
  };

  const coachOptions = (): MenuElement[] => {
    const isSuperCoach = !userContext?.isSubCoach && !isPlayer;
    const coachPermissions = userContext?.permissions || [];
    const isSocialCoachPlus = displayAdminSettings.scPlusTier;
    const manageScriptLibrary = displayAdminSettings.manageScriptLibrary;

    const coachMenu: MenuElement[] = [
      {
        title: "Dashboard",
        path: URLS.coach.dashboard,
      },
    ];

    if (isSuperCoach) {
      coachMenu.push({
        title: "Company Info",
        path: URLS.coach.profile,
      });
    }

    permissions
      .filter(
        p =>
          (p.componentId !== "VIDEO_CATALYST" || isSocialCoachPlus) &&
          (p.componentId !== "MANAGE_SCRIPT_LIBRARY" || manageScriptLibrary)
      )
      .forEach(permission => {
        const currentPermission = coachPermissions.filter(p => p.componentId === permission.componentId);
        if ((currentPermission.length > 0 && availablePermission(currentPermission[0])) || isSuperCoach) {
          const component =
            webComponents[coachType + "_" + permission.componentId] || webComponents[permission.componentId];
          if (component) {
            coachMenu.push(component);
          }
        }
      });

    return coachMenu;
  };

  const editorOptions = (): MenuElement[] => {
    const editorMenu: MenuElement[] = [
      {
        title: "Editing Queue",
        path: URLS.editor.editQueueList,
      },
    ];

    return editorMenu;
  };

  const canGoToMenu = (menuItem: MenuElement, currentStep?: string): boolean => {
    if (currentStep === undefined) {
      return true;
    }

    if (menuItem.path === "") {
      return false;
    }
    switch (menuItem.path) {
      case URLS.coach.dashboard:
        return true;
      case URLS.coach.profile:
        return CoachSignUpStep[currentStep] >= CoachSignUpStep.COACH_DASHBOARD;
      case URLS.coach.pricePlan:
        return CoachSignUpStep[currentStep] >= CoachSignUpStep.COACH_BANK_INFORMATION;
      case URLS.coach.marketingSite:
        return CoachSignUpStep[currentStep] >= CoachSignUpStep.COACH_PRICE_PLAN;
      case URLS.coach.questionnaire:
        return true;
      case URLS.coach.newPrompt:
        return CoachSignUpStep[currentStep] >= CoachSignUpStep.COACH_COACHING_PROFILE || !basicCoach;
      case URLS.coach.players:
        return CoachSignUpStep[currentStep] >= CoachSignUpStep.COACH_PROMPTS;
      case URLS.coach.library:
        return true;
      case URLS.coach.compliance:
        return true;
      case URLS.coach.campaigns:
        return true;
      case URLS.coach.subCoaches:
        return true;
      case URLS.coach.alerts:
        return true;
      case URLS.coach.digestEmails:
        return true;
      case URLS.coach.appSettings:
        return true;
      case URLS.coach.videoCatalyst:
        return true;
      case URLS.videoScripts:
        return true;
      case URLS.player.profile:
        return true;
      case URLS.player.customizations:
        return true;
      case URLS.player.socialAccounts:
        return true;
      case URLS.player.paymentInfo:
        return true;
      case URLS.player.dashboard:
        return true;
      case URLS.player.library:
        return true;
      case URLS.editor.editQueueList:
        return true;
      default:
        return false;
    }
  };

  const sideMenu: MenuElement[] = isEditor ? editorOptions() : isPlayer ? playersOptions() : coachOptions();

  return <SideBarBase history={history} menu={sideMenu} canGoToMenu={canGoToMenu} readyToContinue={readyToContinue} />;
};

export const SideBar = SideBarFC;
