import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Header, Grid, Message, Loader, Transition } from "semantic-ui-react";
import { UserContext } from "@kamino/services";

import { PlayerSocialAccounts, TitleSection, SideBar } from "../../../components";
import { AppContext } from "../../../providers";
import { PlayerSignUpStep} from "../../../services";
import { redirectToPlayerStep } from "../../../utils";

import { WhiteContainer, ContentGrid, InternalGrid } from "../../../styling/baseStyle";
import "./styles.scss";

interface OwnProps {
  successHandler: (user: UserContext) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedPlayerSocialAccounts: React.FC<Props> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);

  const [saved, setSaved] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (userContext?.signUpStep! !== PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      const url = redirectToPlayerStep(userContext?.signUpStep!);
      window.location.assign(url);
    }
  }, [userContext]);

  const savedBanner = () => {
    return (
      saved && (
        <Transition.Group as={Header} duration={300} animation={"drop"} size="tiny" verticalalign="middle">
          <Message info content={"Saved!"} />
        </Transition.Group>
      )
    );
  };

  const errorBanner = (errors: any) => {
    return (
      errors &&
      Object.values(errors).length > 0 &&
      !loading && (
        <Grid.Row columns={1}>
          <Grid.Column width={12}>
            <Message negative list={Object.values(errors) as string[]} />
          </Grid.Column>
        </Grid.Row>
      )
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid>
        <Grid columns={1} className={"socialContent"}>
          <TitleSection
            title={`My Social Accounts`}
            ready={true}
            showLoading={true}
            isLoading={loading}
            disableDuringLoading={true}
            showNextOption={false}
            showLinkOption={false}
            disableButtons={false}
          />

          {/* {savedBanner()} */}
          <InternalGrid columns={1} className="coachBaseContainer">
            <Grid.Column width={8} className={"noPadding centered"}>
              <PlayerSocialAccounts
                type={"PROFILE"}
                showTitle={false}
                deleteEnable={true}
                // handleSocialNetworks={networks => {}}
              />
            </Grid.Column>
          </InternalGrid>
        </Grid>
      </ContentGrid>
      {loading && <Loader active size="large" />}
    </WhiteContainer>
  );
};

export const PlayerMySocialAccounts = withRouter(IntegratedPlayerSocialAccounts);
