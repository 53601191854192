import { Png } from "../../assets";

export const socialNames = {
  _FB: "Facebook",
  _IG: "Instagram",
  _LI: "LinkedIn",
  _TW: "X",
  _TT: "TikTok",
  _IG_STORY: "Instagram",
};

export const socialNetworkNames = {
  _FB: "Facebook",
  _IG: "Instagram",
  _LI: "LinkedIn",
  _TW: "X",
  _TT: "TikTok",
  _IG_STORY: "Instagram Story",
};
export type SocialNetworksId = "IG" | "FB" | "TW" | "LI" | "TT" | "IG_STORY";
export type SocialNetworksName = "facebook" | "twitter" | "linkedIn" | "instagram" | "tiktok";
export const socialNetworksWithName = { FB: "Facebook", IG: "Instagram", LI: "LinkedIn", TW: "X", TT: "TikTok" };
export const socialIcons = {
  "{FB}": Png.FB,
  "{TW}": Png.TW,
  "{LI}": Png.LI,
  "{IG}": Png.IG,
  "{TT}": Png.TT,
  "{IG_STORY}": Png.IG_STORY,
};

export interface SocialNetworkPreLoaded {
  socialNetworkId: SocialNetworksId;
  externalLink?: string;
  status?: string;
}
