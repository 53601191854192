import { map } from "lodash";
import { useIntl } from "react-intl";
import { Dropdown, DropdownItemProps, Grid, Icon, Image } from "semantic-ui-react";
import Highlighter from "react-highlight-words";

import * as DTO from "../../../../interfaces";
import { PostReviewEvent, ReviewStatus, WebComponents } from "../../../../interfaces";
import { AppContext } from "../../../../providers";
import {
  coachWithWritePermission,
  fullNameFrom,
  printBasicDate,
  printDateTime,
  SOCIAL_TITLES,
  URLS,
} from "../../../../utils";
import { MediaPlayer, SocialNetworksSection } from "../../../../components";

import { descriptors, PostType } from "./descriptors";
import { PostHeader, ReviewBy, ReviewDate, SmallTitle } from "./style";

import "./styles.scss";
import { Svg } from "../../../../assets";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";

interface OwnProps {
  post: DTO.Post;
  player?: DTO.PlayerAccount;
  activeSocialNetworks: DTO.SocialNetworksId[];
  changeReviewStatus: (status: any, postSnUrls: string[]) => void;
  onShowHistory: (events: PostReviewEvent[]) => void;
}

interface PostSource {
  label: string;
  link?: string;
}

type Props = OwnProps;

const PostFeedCellFC: FC<Props> = ({
  post: {
    postId,
    promptId,
    videoProjectId,
    message,
    socialNetworks,
    keyphrases,
    coachPostReviewStatus,
    hashtags,
    complianceDisclaimer,
    shortLink,
    coachPostReviewStatusUpdatedDate,
    postReviewRequestedByFullName,
    postReviewRequestedBy,
    mediaGroup,
    postReviewEvents,
  },
  player,
  activeSocialNetworks,
  changeReviewStatus,
  onShowHistory,
}: Props) => {
  const [postSnUrls, setPostSnUrls] = useState<{ [key: string]: string }>({});
  const { formatMessage } = useIntl();
  const { userContext } = useContext(AppContext);

  const postSnNames = socialNetworks.map(sn => sn.socialNetworkId);
  const baseSns: DTO.SocialNetworksId[] = ["FB", "LI", "IG", "IG_STORY", "TW", "TT"];
  const snNamesFiltered = baseSns.filter(bsn => postSnNames.includes(bsn));

  const snFiltered: DTO.SocialNetworkPosted[] = snNamesFiltered.map(
    bsn => socialNetworks.find(sn => sn.socialNetworkId === bsn)!!
  );

  const firstPostedDate = socialNetworks && socialNetworks.length > 0 ? socialNetworks[0].postedTimestamp : "";
  const postSnIds = socialNetworks?.map(sn => sn.socialNetworkId);
  const [reviewStatusOptions, setReviewStatusOptions] = useState<DropdownItemProps[]>([]);
  const [reviewStatus, setReviewStatus] = useState<any | undefined>(coachPostReviewStatus);
  const [selectedSocialNetwork, setSelectedSocialNetwork] = useState<any | undefined>(snFiltered[0]?.socialNetworkId);
  const [postedSocialNetworkOptions, setPostedSocialNetworkOptions] = useState<DropdownItemProps[]>([]);

  const [captionWarning, setCaptionWarning] = useState(false);
  const [transcriptWarning, setTranscriptWarning] = useState(false);
  const withWritePermission = coachWithWritePermission(WebComponents.COMPLIANCE, userContext);

  const urlWithFormat = (url: string | undefined): string | undefined => {
    return url
      ? url.split('"').reduce((result, item) => {
          return `${result}${item}`;
        }, "")
      : url;
  };

  const media = useMemo(() => {
    const mediaList = mediaGroup?.media;
    if (mediaList && mediaList.length > 0) {
      return mediaList[0];
    }
    return undefined;
  }, [mediaGroup?.media]);

  const transcript = useMemo(() => {
    if (media?.transcripts && media.transcripts.length > 0) {
      return media.transcripts[0];
    }
    return undefined;
  }, [media?.transcripts]);

  const transcriptLabel = useMemo(() => {
    if (media?.category === "VIDEO") {
      return "Video Transcript";
    } else {
      return "Image Content";
    }
  }, [media?.category]);

  const needsReview: boolean = useMemo(() => {
    return (
      reviewStatus != ReviewStatus.REVIEWED &&
      reviewStatus != ReviewStatus.COMPLETED &&
      (captionWarning || transcriptWarning)
    );
  }, [captionWarning, transcriptWarning, reviewStatus]);

  const playerName = useMemo(() => {
    return player ? fullNameFrom(player) : "Player";
  }, [player]);

  const postSource: PostSource = useMemo(() => {
    if (videoProjectId) {
      return {
        label: "Video Catalyst",
        link: URLS.coach.videoProject.replace(":id", videoProjectId.toString()),
      };
    }
    if (promptId) {
      return {
        label: "SocialCoach Prompt",
        link: URLS.coach.prompt.replace(":promptId", promptId.toString()),
      };
    }
    return {
      label: `Posted by ${playerName}`,
    };
  }, [playerName, promptId, videoProjectId]);

  useEffect(() => {
    keyphrases.forEach(k => {
      switch (k.location) {
        case "CAPTION":
        case "HASHTAGS":
          setCaptionWarning(true);
          break;
        case "TRANSCRIPT":
          setTranscriptWarning(true);
          break;
      }
    });
  }, [keyphrases]);

  useEffect(() => {
    let snUrls = {};

    socialNetworks?.map(sn => {
      if (sn.externalPostUrl && sn.socialNetworkId !== "FB") {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: urlWithFormat(sn.externalPostUrl!.replace("[", "").replace("]", "")),
        };
      } else {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: sn.externalPostUrl!,
        };
      }
    });
    setPostSnUrls(snUrls);
  }, [socialNetworks]);

  const reviewLabels = {
    [ReviewStatus.REVIEWED]: "Reviewed",
    [ReviewStatus.REQUEST_CHANGES]: "Request Change",
    [ReviewStatus.COMPLETED]: "Completed",
    [ReviewStatus.CHANGES_SUBMITTED]: "Changes Submitted",
  };

  useEffect(() => {
    const filters: DropdownItemProps[] = map(
      [ReviewStatus.REVIEWED, ReviewStatus.REQUEST_CHANGES, ReviewStatus.COMPLETED, ReviewStatus.CHANGES_SUBMITTED],
      rStatus => ({
        key: rStatus,
        text: reviewLabels[rStatus],
        value: rStatus,
      })
    );
    setReviewStatusOptions(filters);
  }, []);

  useEffect(() => {
    const filters: DropdownItemProps[] = map(snFiltered, sn => ({
      key: sn.socialNetworkId,
      text: SOCIAL_TITLES[sn.socialNetworkId],
      value: sn.socialNetworkId,
    }));
    setPostedSocialNetworkOptions(filters);
    setSelectedSocialNetwork(filters[0]?.value);
  }, [socialNetworks]);

  const reviewStatusSection = () => {
    return (
      <>
        <Dropdown
          placeholder="Review Status"
          fluid
          defaultValue={undefined}
          selectOnNavigation={false}
          wrapSelection={false}
          selectOnBlur={false}
          onChange={(_, data) => {
            changeReviewStatus(data.value, Object.values(postSnUrls));
            setReviewStatus(`${data.value}`);
          }}
          selection
          value={reviewStatus}
          options={reviewStatusOptions}
          disabled={coachPostReviewStatus === ReviewStatus.COMPLETED || !withWritePermission}
        />
        {coachPostReviewStatusUpdatedDate && (
          <ReviewDate>
            {formatMessage({ ...descriptors[PostType.requestedDate] }) +
              printBasicDate(coachPostReviewStatusUpdatedDate!!)}
          </ReviewDate>
        )}
        {postReviewRequestedBy && (
          <ReviewBy>{formatMessage({ ...descriptors[PostType.requestedBy] }) + postReviewRequestedByFullName}</ReviewBy>
        )}
        {postReviewEvents && postReviewEvents.length > 0 && (
          <a className={"reviewHistoryLink"} onClick={() => onShowHistory(postReviewEvents)}>
            View Request History
          </a>
        )}
      </>
    );
  };

  const finalMessageOld = useMemo(() => {
    let final = message;
    if (shortLink && shortLink.length > 0 && !message.includes(shortLink)) {
      final += "\n" + shortLink;
    }
    if (hashtags && hashtags.length > 0 && !message.includes(hashtags)) {
      final += "\n\n" + hashtags;
    }
    if (complianceDisclaimer && complianceDisclaimer.length > 0 && !message.includes(complianceDisclaimer)) {
      final += "\n\n" + complianceDisclaimer;
    }

    return final;
  }, [message, hashtags, shortLink, complianceDisclaimer]);

  const useNewFinalMessage = useMemo(() => {
    return snFiltered.some(post => post.caption && post.caption.length > 1);
  }, snFiltered);

  const finalMessage = useMemo(() => {
    if (useNewFinalMessage) {
      const socialPost = snFiltered.find(sn => {
        return sn.socialNetworkId === selectedSocialNetwork;
      });

      return socialPost?.caption;
    } else {
      return finalMessageOld;
    }
  }, [snFiltered, finalMessageOld, useNewFinalMessage, selectedSocialNetwork]);

  const postedSocialNetworksSection = () => {
    return (
      useNewFinalMessage && (
        <Dropdown
          fluid
          defaultValue={selectedSocialNetwork}
          selectOnNavigation={false}
          wrapSelection={false}
          selectOnBlur={false}
          onChange={(_, data) => {
            setSelectedSocialNetwork(`${data.value}`);
          }}
          selection
          options={postedSocialNetworkOptions}
        />
      )
    );
  };

  return (
    <Grid
      divided="vertically"
      columns={3}
      data-elm-id="postListElement"
      key={postId}
      className={`postFeedCell ${needsReview ? "withWarning" : ""}`}
    >
      <Grid.Row columns={1} className={"noPadding"}>
        <Grid.Column>
          <Grid.Row className={"postHeader"}>
            <Image circular size="mini" src={player?.photoUrl || Svg.noImageuser} className={"profileImage"} />
            <div>
              <SmallTitle>{playerName}</SmallTitle>
              <hr />
              <PostHeader>{firstPostedDate && printDateTime(firstPostedDate!!)}</PostHeader>
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3} className={"noPadding"}>
        {/* Post Header Section  */}
        <Grid.Column width={3}>
          <Grid.Row>{media && <MediaPlayer media={media} />}</Grid.Row>
        </Grid.Column>

        {/* Captions  */}
        <Grid.Column width={8}>
          <Grid.Row>
            <SmallTitle>
              <Grid>
                <Grid.Column width={11} className="mediumMarginTop">
                  Caption
                </Grid.Column>
                <Grid.Column width={5} floated="right">
                  {postedSocialNetworksSection()}
                </Grid.Column>
              </Grid>
              {captionWarning && <Icon name={"warning sign"} color={"orange"} className={"warningIcon"} />}
            </SmallTitle>
          </Grid.Row>
          <Grid.Row className={"captionContainer"}>
            <Highlighter
              searchWords={keyphrases.map(k => k.content)}
              textToHighlight={finalMessage || ""}
              highlightClassName={"flaggedHighlight"}
              style={{ whiteSpace: "pre-wrap" }}
            />
          </Grid.Row>
          {transcript && (
            <>
              <Grid.Row className={"secondarySectionHeader"}>
                <SmallTitle>
                  {transcriptLabel}
                  {transcriptWarning && <Icon name={"warning sign"} color={"orange"} className={"warningIcon"} />}
                </SmallTitle>
              </Grid.Row>
              <Grid.Row className={"captionContainer"}>
                <Highlighter
                  searchWords={keyphrases.map(k => k.content)}
                  textToHighlight={transcript.content || ""}
                  highlightClassName={"flaggedHighlight"}
                />
              </Grid.Row>
            </>
          )}
        </Grid.Column>

        {/* Review section  */}
        <Grid.Column width={3}>
          <Grid.Row>
            <SmallTitle>Social Network Links</SmallTitle>
          </Grid.Row>
          <Grid.Row>
            <SocialNetworksSection actives={postSnIds} links={postSnUrls} allSocialNetworks={activeSocialNetworks} />
          </Grid.Row>
          <Grid.Row className={"secondarySectionHeader"}>
            <SmallTitle>Source</SmallTitle>
          </Grid.Row>
          <Grid.Row>
            {postSource.link && (
              <a href={postSource.link} target="_blank" className={"promptLink"}>
                {postSource.label}
              </a>
            )}
            {!postSource.link && postSource.label}
          </Grid.Row>
          <Grid.Row className={"secondarySectionHeader"}>
            <SmallTitle>Review Status</SmallTitle>
          </Grid.Row>
          <Grid.Row>{reviewStatusSection()}</Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const PostFeedCell = PostFeedCellFC;
