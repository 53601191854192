import { client as axios } from "./axiosClient";
import { Constants } from "@kamino/services";
import { catchServiceException } from "@kamino/services";
import { StickerResponse, StickerRequest } from "../interfaces";

const STICKER_SERVICE = `${Constants.API_URL}/coaches/stickers`;

export class StickerServices {
 @catchServiceException()
  static async get(coachId: string): Promise<StickerResponse> {
    const { data } = await axios.get<StickerResponse>(`${STICKER_SERVICE}/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

 @catchServiceException()
  static async update(coachId: string, stickerRequest: StickerRequest): Promise<StickerResponse> {
    const { data } = await axios.put<StickerResponse>(
      `${STICKER_SERVICE}/${coachId}`,
      {
        ...stickerRequest,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
