import * as React from "react";
import { Image, Grid } from "semantic-ui-react";
import { Png } from "../../assets";
import { SocialNetworksId } from "../../interfaces";
import { IconContainer, SocialIcon } from "./styled";

import "./styles.scss";
interface Props {
  /**
   * Social network identifier (IG, FB, LI, TW, TT)
   */
  networkId: SocialNetworksId;

  /**
   * Enable to action
   */
  disabled?: boolean;

  /**
   * Indicates if social network is active
   */
  active?: boolean;

  /**
   * Map identifier
   */
  index?: string | number | undefined;

  customIconClassname?: string;
  /**
   * Indicates social network icon is size
   */
  size?: "mini" | "big" | "large" | "noMargin" | "noMarginNoPadding";
  link?: string;
  withPadding?: boolean;
  customProps?: any;
  onClickHandler?: (checked: boolean) => void;
}

export const SocialNetworkIconButton: React.FC<Props> = ({
  disabled = true,
  networkId,
  active = false,
  index = 0,
  size = "large",
  link,
  withPadding = true,
  customIconClassname = "",
  customProps = { columns: 5 },
  onClickHandler,
}) => {
  const socialIcons = {
    "{FB}": Png.FB,
    "{TW}": Png.TW,
    "{LI}": Png.LI,
    "{IG}": Png.IG,
    "{TT}": Png.TT,
    "{IG_STORY}": Png.IG_STORY,
  };

  const socialTitles = {
    FB: "Facebook",
    TW: "X (Twitter)",
    LI: "LinkedIn",
    IG: "Instagram",
    TT: "TikTok",
    IG_STORY: "Instagram Story",
  };

  return (
    <Grid.Column {...customProps} key={index} className={size + "SocialIconButtonColumn"}>
      <IconContainer
        disabled={disabled}
        data-elm-id={"socialIcon" + networkId}
        target="_blank"
        href={link}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
          } else if (onClickHandler) {
            onClickHandler(!active);
          }
        }}
      >
        <SocialIcon
          custompadding={withPadding ? "3px" : "0px"}
          customsize={size}
          selected={active}
          title={socialTitles[networkId]}
          src={socialIcons["{" + networkId + "}"]}
          size="small"
          circular
          className={customIconClassname}
        />
      </IconContainer>
    </Grid.Column>
  );
};
