import * as React from "react";
import { useIntl } from "react-intl";
import { map } from "lodash";
import { Dropdown, DropdownItemProps, Grid } from "semantic-ui-react";

import { PlayerInfoSection } from "../../../components";
import { PlayerAccount, SocialNetworkPosted, SocialNetworksId, VideoProjectDetail } from "../../../interfaces";
import { PlayersServices } from "../../../services";
import { SOCIAL_TITLES } from "../../../utils";

import { VideosSection } from "./components/VideosSection";
import { VideoProjectComponentType, descriptors } from "./descriptors";

import "../../../styling/datepicker.scss";
import { CaptionSection } from "./styled";
import "./styles.scss";
interface OwnProps {
  coachId: string;
  videoProject: VideoProjectDetail;
}

type Props = OwnProps;

/**
 * Represents a Prompt content.
 */
const VideoProjectDetailClass: React.FC<Props> = ({ videoProject, coachId }) => {
  const { formatMessage } = useIntl();

  const { post } = videoProject;

  const postSnNames = post?.socialNetworks.map(sn => sn.socialNetworkId) || [];
  const baseSns: SocialNetworksId[] = ["FB", "LI", "IG", "IG_STORY", "TW", "TT"];
  const snNamesFiltered = baseSns.filter(bsn => postSnNames.includes(bsn));

  const snFiltered: SocialNetworkPosted[] = snNamesFiltered.map(
    bsn => post?.socialNetworks.find(sn => sn.socialNetworkId === bsn)!!
  );
  // Player section
  const [playerInfo, setPlayerInfo] = React.useState<PlayerAccount>();

  const [selectedSocialNetwork, setSelectedSocialNetwork] = React.useState<any | undefined>(
    snFiltered[0]?.socialNetworkId
  );
  const [postedSocialNetworkOptions, setPostedSocialNetworkOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    PlayersServices.searchSubscribedAllPlayersByTag(coachId).then(response => {
      const player = response.content.find(p => p.playerAccount.playerId === videoProject.playerId);
      setPlayerInfo(player?.playerAccount);
    });
  }, []);

  React.useEffect(() => {
    return () => {
      window.removeEventListener("loadedmetadata", () => {
        // Nothing
      });
    };
  }, []);

  React.useEffect(() => {
    const filters: DropdownItemProps[] = map(snFiltered, sn => ({
      key: sn.socialNetworkId,
      text: SOCIAL_TITLES[sn.socialNetworkId],
      value: sn.socialNetworkId,
    }));
    setPostedSocialNetworkOptions(filters);
    setSelectedSocialNetwork(filters[0]?.value);
  }, [post?.socialNetworks]);

  const withCaption = React.useMemo(() => {
    return snFiltered.some(sn => sn.caption && sn.caption.length > 0);
  }, [snFiltered]);

  const postedSocialNetworksSection = () => {
    return (
      withCaption && (
        <Dropdown
          fluid
          defaultValue={selectedSocialNetwork}
          selectOnNavigation={false}
          wrapSelection={false}
          selectOnBlur={false}
          onChange={(_, data) => {
            setSelectedSocialNetwork(`${data.value}`);
          }}
          selection
          options={postedSocialNetworkOptions}
        />
      )
    );
  };

  const finalMessage = React.useMemo(() => {
    if (withCaption) {
      const socialPost = snFiltered.find(sn => sn.socialNetworkId === selectedSocialNetwork);
      return socialPost?.caption;
    } else {
      return videoProject.messageToPost;
    }
  }, [selectedSocialNetwork, snFiltered, videoProject.messageToPost, withCaption]);

  const captionSection = (
    <div className="mediumPaddingTop">
      <div>
        <Grid>
          <Grid.Column width={8}>
            {" "}
            <label>{formatMessage({ ...descriptors[VideoProjectComponentType.message] })}</label>
          </Grid.Column>
          {withCaption && <Grid.Column width={5}>{postedSocialNetworksSection()}</Grid.Column>}
        </Grid>
      </div>
      <CaptionSection data-elm-id="videoProjectDetailCaption">{finalMessage}</CaptionSection>
    </div>
  );

  return (
    <div className={"videoProjectDetailContainer"}>
      {/* PLAYER INFO */}
      <PlayerInfoSection playerInfo={playerInfo} showPhone={true} />

      {/* Files section */}
      {videoProject.originalMedia && (
        <VideosSection
          title={formatMessage({ ...descriptors[VideoProjectComponentType.originalVideosDesc] })}
          type="ORIGINAL"
          videos={videoProject.originalMedia?.media!!}
          videoProjectId={videoProject.videoProjectId!!}
          date={videoProject.createdDate!!}
        />
      )}

      {videoProject.editedMedia && (
        <VideosSection
          title={formatMessage({ ...descriptors[VideoProjectComponentType.editedVideosDesc] })}
          type="EDITED"
          videos={videoProject.editedMedia?.media!!}
          videoProjectId={videoProject.videoProjectId!!}
          date={videoProject.editedDate!!}
        />
      )}
      {captionSection}
    </div>
  );
};

export const VideoProjectDetailComponent = VideoProjectDetailClass;
