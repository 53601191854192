import * as React from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import { Svg } from "../../assets";
import { PlayerAccount } from "../../interfaces";
import { fullNameFrom } from "../../utils";

interface Props {
  playerInfo?: PlayerAccount;
  showPhone?: boolean;
}

const PlayerInfoSectionFC: React.FC<Props> = ({ playerInfo, showPhone }) => {
  return (
    <Grid.Row className={"containerPlayerInfoSection"}>
      <Image circular wrapped size="tiny" src={playerInfo?.photoUrl || Svg.noImageuser} />
      <div className="smallPaddingLeft">
        <Header as="h3" textAlign="left" className="noMargin noPadding">
          {playerInfo ? fullNameFrom(playerInfo!) : ""}
        </Header>
        <Header as="p" textAlign="left" className="noMargin noPadding">
          {playerInfo ? playerInfo.email : ""}
        </Header>
        {showPhone && (
          <Header as="p" textAlign="left" className="noMargin noPadding">
            {playerInfo?.phone || ""}
          </Header>
        )}
      </div>
    </Grid.Row>
  );
};

export const PlayerInfoSection = PlayerInfoSectionFC;
