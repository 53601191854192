import { client as axios } from "./axiosClient";
import { Constants } from "@kamino/services";
import { catchServiceException } from "@kamino/services";
import { UserRegistered } from "../interfaces/dtos";
import { PlayerSignUpStep } from ".";

export class LoginStepServices {
 @catchServiceException()
  static async sendStep(coachSignUpStep: string): Promise<boolean> {
    const response = await axios.put<UserRegistered>(
      `${Constants.API_URL}/accounts/coach/step`,
      {
        coachSignUpStep,
      },
      {
        withCredentials: true,
      }
    );
    return Promise.resolve(response.status === 200);
  }

 @catchServiceException()
  static async sendPlayerStep(playerStep: PlayerSignUpStep): Promise<boolean> {
    const response = await axios.put<UserRegistered>(
      `${Constants.API_URL}/accounts/player/step`,
      {
        playerSignUpStep: PlayerSignUpStep[playerStep],
      },
      {
        withCredentials: true,
      }
    );
    return Promise.resolve(response.status === 200);
  }
}
