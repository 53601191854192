import {
  SocialNetwork,
  Profile,
  Account,
  SocialNetworksId,
  PricePlanTier,
  MediaGroup,
  SendModeType,
  ReviewStatus,
  SocialNetworkMessage,
  Media,
} from "../../interfaces";
import { SCAccount } from "../../providers";
import { Plan } from "./Plan";
import { PlayerTags } from "./Search";
import { BasePlayerStatistics } from "./Statistics";
type PaymentStatus = "SUCCESSFUL" | "FAILED" | "AVAILABLE" | "TRANSFERRED";

export enum SubscriptionType {
  ENTERPRISE = "ENTERPRISE",
  SINGLE = "SINGLE",
}

export enum SubscriptionStatus {
  PENDING_PAYMENT = "PENDING_PAYMENT",
  FREE_TRIAL = "FREE_TRIAL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  CANCELED = "CANCELED",
}

export interface BasePlayer {
  id: number;
  email?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  photoUrl?: string;
  creationDate?: string;
  leadsNeedToCloseTransaction?: number;
  averageIncomesPerTransaction?: number;
  transactionsClosedLastYear?: number;
  webAddress?: string;
  playerLinkId?: string;
  addHashTagAutomatically?: boolean;
}

export interface AccountResumen {
  user: SCAccount;
  profile: Profile;
  player?: BasePlayer;
}
export interface PlayerAccount {
  userId: number;
  playerId?: number;
  username?: string;
  email?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  photoUrl?: string;
  status?: string;
  socialNetworks?: SocialNetwork[];
  latestLoginDate?: string;
  verificationDate?: string;
  createdDate?: string;
  addHashTagAutomatically?: boolean;
  complianceDisclaimer?: string;
}

export interface PlayerSearchResponse {
  playerId: number;
  userId: number;
  phone?: string;
  email?: string;
  photoUrl?: string;
  firstName: string;
  lastName: string;
  socialNetworks?: SocialNetwork[];
  lastLoginDate?: string;
  verificationDate?: string;
  subscriptionSummary?: SubscriptionSummary;
  coachProfile?: SubscriptionCoachInfo;
}

export interface SubscriptionCoachInfo {
  coachId: number;
  email?: string;
  firstName: string;
  lastName: string;
  companyName: string;
}

export interface SubscriptionSummary {
  type: SubscriptionType;
  status: SubscriptionStatus;
  tier?: PricePlanTier;
}

export interface PlayerPlanSubscription {
  id: number;
  playerId: number;
  pricePlan: Plan;
  status: SubscriptionStatus;
  endPeriodDate?: string;
}

export interface PromptActivity {
  playerId: number;
  countPromptReceived: number;
  countPromptPosted: number;
  lastSuccessfulDeliveryDate: string;
  lastScheduledDeliveryDate: string;
}

export interface SubscribedPlayerSearchResponse {
  playerAccount: PlayerAccount;
  lastPaymentStatus?: PaymentStatus;
  canceledDate?: string;
  pricePlanSubscription?: PlayerPlanSubscription;
  tags?: PlayerTags[];
  promptActivity?: PromptActivity;
  branchNames?: string[];
  regionNames?: string[];
  stateCodes?: string[];
  costCenterNames?: string[];
}

export interface PlayerSubscriptionActivityBySocialNetwork extends PromptActivity {
  socialNetwork: SocialNetworksId;
}

export interface SubscribedPlayerSearchBySocialNetworkResponse {
  playerAccount: PlayerAccount;
  lastPaymentStatus?: PaymentStatus;
  pricePlanSubscription?: PlayerPlanSubscription;
  tags?: PlayerTags[];
  promptActivity?: PromptActivity;
  branchNames?: string[];
  regionNames?: string[];
  costCenterNames?: string[];
  stateCodes?: string[];
  promptActivityBySocialNetwork: PlayerSubscriptionActivityBySocialNetwork[];
}

export interface PlayerRegistered {
  profile: Profile;
  player?: { id: number };
  user: Account;
}

export interface SubscribedPlayerWithStatsSearchResponse {
  player: SubscribedPlayerSearchResponse;
  statistics: BasePlayerStatistics;
  statsBySocialNetwork: { [key: string]: BasePlayerStatistics };
}

export interface SubscribedPlayerWithSplittedStatsSearchResponse {
  player: SubscribedPlayerSearchBySocialNetworkResponse;
  statistics: BasePlayerStatistics;
  statsBySocialNetwork: { [key: string]: BasePlayerStatistics };
}

export interface TotalStats {
  countPromptPosted: number;
  likes: number;
  followers: number;
  comments: number;
  shares: number;
  views: number;
  click: number;
}

export interface PlayerResumeStats extends TotalStats {
  statsBySocialNetwork?: { [key: string]: TotalStats };
}

export interface PlayerWithStats {
  photoUrl?: string;
  info: PlayerAccount;
  email: string;
  countPromptReceived: number;
  countPromptPosted: number;
  likes: number;
  followers: number;
  comments: number;
  shares: number;
  views: number;
  click: number;
  statsBySocialNetwork: { [key: string]: TotalStats };
  countPromptPostedSplitted: { [key: string]: number };
  branchNames?: string[];
  regionNames?: string[];
  costCenterNames?: string[];
}

export interface PlayerWithCampaignStats {
  photoUrl?: string;
  info: PlayerAccount;
  email: string;
  countPromptReceived: number;
  countPromptPosted: number;
  countPromptRemaining?: number;
}

export interface PlayerWithBranch {
  photoUrl?: string;
  info: PlayerAccount;
  email: string;
  region: string;
  branch: string;
  state: string;
}

export interface Region {
  regionId: string;
  coachId: string;
  name: string;
}

export interface State {
  stateId: string;
  coachId: string;
  stateCode: string;
}

export interface Branch {
  branchId: string;
  coachId: string;
  name: string;
}

export interface CostCenter {
  costCenterId: string;
  coachId: string;
  name: string;
}

export interface PlayerQuestion {
  key: string;
  content: string;
  answerType: string;
  contentDescriptor: string;
}

export interface DashboardStatistics {
  views?: Statistic;
  clicks?: Statistic;
  earned?: Statistic;
  followers?: Statistic;
  goalsPosted?: Statistic;
  likes?: Statistic;
  shares?: Statistic;
  comments?: Statistic;
}

export interface Statistic {
  percent: number;
  value: number;
}

export interface StatisticGoal {
  currentGoals: number;
  expectedGoals: number;
}

export interface DashboardInformation {
  statistics?: DashboardStatistics;
}

export type ActionStyle = "DEFAULT" | "PREMIUM" | "URGENT";
export type ActionStyleLoweCase = "default" | "premium" | "urgent";
export type ActionTarget =
  | "CUSTOMIZATIONS"
  | "SOCIAL_NETWORKS"
  | "SCRIPT_LIBRARY"
  | "PROMPT_LIBRARY"
  | "PROJECT_LIBRARY"
  | "CAMPAIGNS"
  | "NEW_VIDEO_PROJECT"
  | "NEW_POST";

export interface PlayerQuickAction {
  title: string;
  subtitle: string;
  actionStyle: ActionStyle;
  actionTarget: ActionTarget;
}

export interface PlayerQuickActionResponse {
  data: PlayerQuickAction[];
}

export interface PostReviewEvent {
  id: number;
  userId: number;
  username: string;
  userRole: "PLAYER" | "COACH";
  message?: string;
  status?: ReviewStatus;
  postId: number;
  createdDate: string;
}
export interface PlayerPost {
  createdDate: string;
  keyphrases: string[];
  mediaGroup: MediaGroup;
  scheduledDatetimeUtc: any;
  message: string;
  playerId: number;
  postId: number;
  promptId?: number;
  sendMode: SendModeType;
  campaignPlayerId?: number;
  hashtags?: string;
  coachPostReviewStatus?: ReviewStatus;
  socialNetworks: SocialNetworkMessage[];
  postReviewEvents?: PostReviewEvent[];
}

export type ReadyToPostContentType = "PROMPT" | "VIDEO_PROJECT";
export interface PostReadyContent {
  title: string;
  subtitle: string;
  contentType: ReadyToPostContentType;
  contentId: number;
  media: Media;
}
