import { styled } from "@kamino/ui";
import { Button, Grid, Input, Label } from "semantic-ui-react";

export const FormTitle = styled(Grid)({
  margin: " 0 !important",
  padding: "10px 0px !important",
});
export const LabelTitle = styled(Label)({
  background: "white !important",
  padding: "0px !important",
});

export const NameInput = styled(Input)`
  input {
    text-transform: uppercase;
  }
`;

export const PayButton = styled(Button)`
  width: 100%;
`;

export const InputWrapper = styled.div<{ focus: boolean }>`
  border-radius: 7px;
  border: 1px solid ${(props: any) => (props.focus ? "#85b7d9" : "#e9e9e9")};
  height: 45px;
  padding: 0.67857143em 1em;
  background-color: white;
  min-width: 275px;
`;

export const ExpirationWrapper = styled.div<{ focus: boolean }>`
  border-radius: 7px;
  border: 1px solid ${(props: any) => (props.focus ? "#85b7d9" : "#e9e9e9")};
  height: 45px;
  padding: 0.67857143em 1em;
  background-color: white;
  width: 206px;
`;

export const CvsWrapper = styled.div<{ focus: boolean }>`
  border-radius: 7px;
  border: 1px solid ${(props: any) => (props.focus ? "#85b7d9" : "#e9e9e9")};
  height: 45px;
  padding: 0.67857143em 1em;
  background-color: white;
  width: 205px;
`;

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#474c58",
      fontFamily: "Lato",
      fontSmoothing: "antialiased",
      textTransform: "uppercase",
      "::placeholder": {
        color: "#dddfe3",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
