import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Types } from "@kamino/services";
import { useCookies } from "react-cookie";


import { LoginForm } from "../LoginForm";

import { BrandHeader } from "../../components";
import { BrandResponse } from "../../interfaces";
import { AppUserContext } from "../../providers";
import { BrandServices, CoachSignUpStep } from "../../services";
import { URLS, cleanBrandInfoInLocalStorage, getBrandRedirectUrl, redirectToPlayerStepWithRedirect } from "../../utils";


import { FloatedContainer } from "../../styling/baseStyle";
import "./styles.scss";
interface OwnProps {
  group: Types.UserGroups;
}

type Props = OwnProps & RouteComponentProps;

const LoginPageClass: React.FC<Props> = ({ history, group }) => {
  const [cookies, setCookie] = useCookies(["user-sc-register"]);
  const [brand, setBrand] = React.useState<BrandResponse | undefined>(undefined);

  const brandId = localStorage.getItem("brandId");

  const redirectToStep = (step: string, context?: AppUserContext) => {
    const redirectTo = getBrandRedirectUrl();
    if (redirectTo !== undefined) {
      cleanBrandInfoInLocalStorage();
      history.push(redirectTo!!);
    } else {
      switch (step) {
        case CoachSignUpStep[CoachSignUpStep.COACH_EMAIL_PASSWORD]:
        case CoachSignUpStep[CoachSignUpStep.COACH_PROFILE_DATA]:
        case CoachSignUpStep[CoachSignUpStep.COACH_COMPANY_INFORMATION]:
          if (context) {
            setCookie("user-sc-register", context);
          }
          history.push("/sign-up/1"); // Step 2
          break;
        case CoachSignUpStep[CoachSignUpStep.COACH_FINISH_SIGN_UP]:
        case CoachSignUpStep[CoachSignUpStep.COACH_PROMPTS]:
          history.push(URLS.coach.dashboard);
          break;
        case CoachSignUpStep[CoachSignUpStep.COACH_DASHBOARD]:
          history.push(URLS.coach.profile);
          break;
        case CoachSignUpStep[CoachSignUpStep.COACH_BANK_INFORMATION]:
          history.push(URLS.coach.profile);
          break;
        case CoachSignUpStep[CoachSignUpStep.COACH_PRICE_PLAN]:
          history.push(URLS.coach.marketingSite);
          break;
        case CoachSignUpStep[CoachSignUpStep.COACH_COACHING_PROFILE]:
          history.push(URLS.coach.library);
          break;
        case CoachSignUpStep[CoachSignUpStep.COACH_CUSTOMER_QUESTIONNAIRE]:
          history.push(URLS.coach.library);
          break;
        default:
          history.push(URLS.coach.dashboard);
          break;
      }
    }
  };

  React.useEffect(() => {
    if (brandId !== null) {
      BrandServices.get(brandId).then(setBrand);
    }
  }, [brandId]);

  return (
    <>
      {brand && <BrandHeader customUrl={brand.logoUrl} />}
      <FloatedContainer>
        <LoginForm
          group={group}
          successHandler={(context: AppUserContext) => {
            const { signUpStep } = context;
            if (context.coach) {
              return redirectToStep(signUpStep!!, context);
            } else if (group === Types.UserGroups.USER && context.player) {
              return history.push(redirectToPlayerStepWithRedirect(signUpStep!!));
            }
          }}
        />
      </FloatedContainer>
    </>
  );
};

export const LoginPage = withRouter(LoginPageClass);
