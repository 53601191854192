import { useState, useEffect } from "react";
import { SettingsServices } from "../../services";
import { AdminWebSetting, WebSettings } from "../../interfaces";
import { availableFunctionality } from "../../utils";

const useAdminCoachSettings = (coachId?: string) => {
  const [adminSettingLoading, setAdminSettingLoading] = useState<boolean>(false);

  const [displayAdminSettings, setDisplayAdminSettings] = useState<{
    costCenter: boolean;
    scPlusTier: boolean;
    postFailed: boolean;
    playerReport: boolean;
    manageScriptLibrary: boolean;
  }>({
    costCenter: false,
    scPlusTier: false,
    postFailed: false,
    playerReport: false,
    manageScriptLibrary: false,
  });

  useEffect(() => {
    const setPermissions = (adminSettings: AdminWebSetting): void => {
      setDisplayAdminSettings({
        costCenter: availableFunctionality(adminSettings, WebSettings.COST_CENTER),
        scPlusTier: availableFunctionality(adminSettings, WebSettings.SC_PLUS_TIER),
        postFailed: availableFunctionality(adminSettings, WebSettings.FAILED_POST_NOTIFICATION),
        playerReport: availableFunctionality(adminSettings, WebSettings.SUMMARY_REPORT_COACH),
        manageScriptLibrary: availableFunctionality(adminSettings, WebSettings.MANAGE_SCRIPT_LIBRARY),
      });
      setAdminSettingLoading(false);
    };

    const getSettings = async () => {
      SettingsServices.getAdminSettingsByCoachId(coachId!!).then(setPermissions);
    };

    if (coachId) {
      setAdminSettingLoading(true);
      getSettings();
    }
  }, [coachId]);

  return {
    adminSettingLoading,
    displayAdminSettings,
  };
};

export default useAdminCoachSettings;
