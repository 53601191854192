import * as React from "react";
import { SessionContextProvider, SessionContext } from "@kamino/ui";
import {
  BaseCoach,
  Profile,
  BasePlayer,
  CoachPermission,
  LandingResponse,
  PaymentDetails,
  Subscription,
  UserGroups,
} from "../interfaces/dtos";
import { Account } from "@kamino/services";

interface PlayerContextState {
  coachInformation?: LandingResponse;
  setCoachInformation: (coachInfo?: LandingResponse) => void;
}

export const PlayerAppContext = React.createContext<PlayerContextState>({
  setCoachInformation(coachInfo?: LandingResponse) {
    // default function
  },
});

export interface AppUserContext {
  account: SCAccount;
  profile: Profile;
  signUpStep?: string;
  coach?: BaseCoach;
  player?: BasePlayer;
  isSubCoach?: boolean;
  permissions?: CoachPermission[];
  subscriptions?: Subscription[];
  paymentMethods?: PaymentDetails[];
}

// export enum SCUserGroups {
//   COACH = "COACH",
//   PLAYER = "PLAYER",
//   ADMIN = "ADMIN",
//   EDITOR = "EDITOR",
// }

export interface SCAccount extends Account {
  username?: string;
  email?: string;
  accountType?: string; // "PROPIETARY";
  scGroups?: UserGroups[];
  isDemo?: boolean;
}

export interface AppContextState {
  userContext?: AppUserContext;
  setUserContext: (userContext?: AppUserContext) => void;
  loading: boolean;
}

export const AppContext = React.createContext<AppContextState>({
  setUserContext(userContext?: AppUserContext) {
    // default function
  },
  loading: false,
});

type AppContextProviderProps = {
  children: React.ReactNode; // 👈️ type children
};

export const AppContextProvider: React.FC<AppContextProviderProps> = (props: AppContextProviderProps) => {
  const [coachInformation, setCoachInformation] = React.useState(undefined);
  const coachInfo = React.useMemo(() => ({ coachInformation, setCoachInformation }), [coachInformation]);

  return (
    <PlayerAppContext.Provider value={coachInfo as PlayerContextState}>
      <SessionContextProvider>
        <SessionContext.Consumer>
          {sessionContext => {
            const state = sessionContext as AppContextState;
            return <AppContext.Provider value={state}>{props.children}</AppContext.Provider>;
          }}
        </SessionContext.Consumer>
      </SessionContextProvider>
    </PlayerAppContext.Provider>
  );
};


// export const SessionContextProviderFC: React.FC<AppContextProviderProps> = (props: AppContextProviderProps) => {
//   return (<SessionContextProvider render={() => props.children}/>)
// }