import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";

import { SideBar, TitleSection } from "../../../components";
import { AppContext } from "../../../providers";
import { PlayerSignUpStep } from "../../../services";
import { redirectToPlayerStep } from "../../../utils";

import { Statistics } from "./components/Statistics";
import { QuickActions } from "./components/QuickActions";
import { NeedsAttention } from "./components/NeedsAttention";
import { ReadyToPost } from "./components/ReadyToPost";

import { descriptors, DashboardType } from "./descriptors";
import "./styles.scss";

type Props = RouteComponentProps;

const DashboardFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);

  const playerId = userContext!!.player!!.id;
  React.useEffect(() => {
    console.log("signUpStep", userContext?.signUpStep);
    if (userContext?.signUpStep! !== PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      const url = redirectToPlayerStep(userContext?.signUpStep!);
      window.location.assign(url);
    }
  }, [userContext]);

  return (
    <div className={"whiteContainer"}>
      <SideBar history={history} readyToContinue={true} />
      <Grid className={"dashboardContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[DashboardType.title] })}
            ready={true}
            showNextOption={false}
          />

          <Statistics playerId={playerId} coachId={Number(userContext!!.subscriptions!![0].pricePlan.coachId)} />

          <QuickActions playerId={playerId} />

          <NeedsAttention playerId={playerId} />

          <ReadyToPost playerId={playerId} />
        </Grid>
      </Grid>
    </div>
  );
};

export const PlayerDashboard = withRouter(DashboardFC);
